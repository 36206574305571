import Request from "./request";
import Utils from "../Utils";

const SERVER = "main";

export async function list(limit, offset = 0, token) {
  try {
    return await Request.get(
      SERVER,
      `general_typologies/paginate/${limit}/${offset}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::general_typologies::list", error);
  }
}

//  TODO: not implemented in the API
export async function filter(name, limit, offset = 0, token) {
  try {
    return await Request.get(
      SERVER,
      `general_typologies/filter/${name}/${limit}/${offset}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::general_typologies::filter", error);
  }
}

export async function create(general_typology, token) {
  try {
    return await Request.post(SERVER, "general_typologies", token, {
      general_typology,
    });
  } catch (error) {
    Utils.debugVar("Connectors::general_typologies::create", error);
  }
}

export async function read(id, token) {
  try {
    return await Request.get(SERVER, `general_typology/${id}`, token);
  } catch (error) {
    Utils.debugVar("Connectors::general_typology::read", error);
  }
}

export async function update(general_typology, token) {
  try {
    return await Request.put(
      SERVER,
      `general_typology/${general_typology.id}`,
      token,
      {
        general_typology,
      }
    );
  } catch (error) {
    Utils.debugVar("Connectors::general_typology::update", error);
  }
}

export async function remove(general_typology, token) {
  try {
    return await Request.delete(
      SERVER,
      `general_typology/${general_typology.id}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::general_typology::remove", error);
  }
}
