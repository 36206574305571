import Request from "./request";
import Utils from "../Utils";

const SERVER = "main";

export async function list(limit, offset = 0, token) {
  try {
    return await Request.get(
      SERVER,
      `services/paginate/${limit}/${offset}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::services::list", error);
  }
}

//  TODO: not implemented in the API
export async function filter(name, limit, offset = 0, token) {
  try {
    return await Request.get(
      SERVER,
      `services/filter/${name}/${limit}/${offset}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::services::filter", error);
  }
}

export async function create(service, token) {
  try {
    return await Request.post(SERVER, "services", token, { service });
  } catch (error) {
    Utils.debugVar("Connectors::services::create", error);
  }
}

export async function read(id, token) {
  try {
    return await Request.get(SERVER, `service/${id}`, token);
  } catch (error) {
    Utils.debugVar("Connectors::service::read", error);
  }
}

export async function update(service, token) {
  try {
    return await Request.put(SERVER, `service/${service.id}`, token, {
      service,
    });
  } catch (error) {
    Utils.debugVar("Connectors::service::update", error);
  }
}

export async function remove(serviceId, token) {
  try {
    return await Request.delete(SERVER, `service/${serviceId}`, token);
  } catch (error) {
    Utils.debugVar("Connectors::service::remove", error);
  }
}
