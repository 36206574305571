<template>
  <nav
    id="left-side-navbar"
    class="row m-0 h-100 col p-0"
    :class="!$store.getters.isConnected ? 'lrk-bg-dark-forced' : ''"
  >

    <div id="logo" class="d-block mx-auto">
      <div>
        <img
          class="icon"
          src="./assets/images/logo-icon.svg"
          width="100"
          alt=""
        />
      </div>
      <div :style="{ marginTop: '15px' }">
        <img src="./assets/images/logo.min.svg" width="150" alt="" />
      </div>
    </div>

    <div
      v-if="$store.getters.isConnected"
      class="lrk-bg-dark col-md-12 p-3"
      id="left-side-navbar-title-section"
    >
      <h1 class="text-center">
        <img src="./assets/images/logo.min.svg" width="150" alt="" />
      </h1>
    </div>

    <div
      v-if="$store.getters.isConnected"
      id="left-side-navbar-content"
      class="col-md-12"
    >
      <div v-if="$isDevelopment() || $isTest() || ($isProduction() && $store.getters.roles.includes('IT Guys'))"
           class="w-100 my-1 btn border-0 rounded-0" :class="$isProduction()? 'btn-danger' : 'btn-success'"
      >ENV: {{ $mode() }}</div>
      <div v-if="apiStatus!==null && ($isDevelopment() || $isTest() || ($isProduction() && $store.getters.roles.includes('IT Guys')))"
           class="w-100 my-1 btn btn-info border-0 rounded-0"
      >
        <ul class="list-unstyled text-left my-0">
          <li>Api: {{ apiStatus.app }}</li>
          <li>Env: {{ apiStatus.mode }}</li>
          <li>Time: {{ apiStatus.time }}</li>
        </ul>
      </div>
      <div v-if="apiStatus===null && apiIsDown && ($isDevelopment() || $isTest() || ($isProduction() && $store.getters.roles.includes('IT Guys')))"
           class="w-100 my-1 btn btn-danger border-0 rounded-0"
      >API DOWN</div>
      <div v-if="apiStatus===null && !apiIsDown && ($isDevelopment() || $isTest() || ($isProduction() && $store.getters.roles.includes('IT Guys')))"
           class="w-100 my-1 btn btn-info border-0 rounded-0"
      >WAITING API...</div>

      <nav-panel v-if="$store.getters.isConnected && !apiIsDown" />
    </div>
  </nav>
  <div id="content-panel" class="col row m-0">
    <router-view :API_IS_DOWN="apiIsDown"></router-view>
  </div>
  <transition name="loading">
    <div v-show="loading" id="loading" class="lrk-bg-dark">
      <div class="mx-auto w-50">
        <div>
          <img src="./assets/images/logo-icon.svg" width="50" alt="" />
        </div>
        <div :style="{ marginTop: '15px' }">
          <img src="./assets/images/logo.min.svg" width="170" alt="" />
        </div>
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import NavPanel from "./layout-components/NavPanel";

export default {
  name: "App",
  components: {
    NavPanel,
  },
  data: () => {
    return {
      loading: false,
      apiStatus: null,
      apiIsDown: false,
    };
  },
  created: function () {
    setInterval(async () => {
      if (this.$store.getters.isConnected) {
        if (!(await this.$API.get("firebase").checkToken())) {
          this.$EventManager.triggerRefreshUserInfo();
        }
      }
    }, 2000);

    this.checkApi()
    if (this.$isDevelopment() || this.$isTest() || (this.$isProduction() && this.$store.getters.roles.includes('IT Guys'))){
      setInterval(this.checkApi, 60000)
    }



    this.$EventManager.onLogin(() => {
      this.$forceUpdate();
    });
    this.$EventManager.onLogout(() => {
      this.$forceUpdate();
    });
    this.$EventManager.onLoadingStart(() => {
      this.loading = true;
    });
    this.$EventManager.onLoadingEnd(() => {
      this.loading = false;
    });
    this.$EventManager.onRefreshUserInfo((e) => {
      this.$store.dispatch("refresh", { callback: e.detail.callback });
    });

    this.$store.dispatch("refresh");
  },
  methods: {
    checkApi: async function() {
      let data = await this.$API.get('check_api').status()
      if(data){
        this.apiStatus = {
          ...data,
          time: new Date(data.time * 1000).toLocaleString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
          })
        }

        this.apiIsDown = false
      }else{
        this.apiStatus = null
        this.apiIsDown = true
        this.$store.dispatch("logout");
      }
    }
  }
};
</script>

<style scoped>
#logo {
  position: absolute;
  bottom: 50px;
  left: 75px;
}

#logo .icon {
  margin-left: 36px;
  margin-bottom: 4px;
  width: 75px;
}
#loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 10000;
}

#loading > div {
  text-align: center;
  margin-top: 350px;
}
#loading > div > .spinner-border {
  width: 400px;
  height: 400px;
  margin-top: -250px;
}

.loading-enter-active {
  transition: opacity 0.3s ease-in;
}
.loading-leave-active {
  transition: opacity 0.3s ease-in;
}
.loading-enter-from,
.loading-leave-to {
  opacity: 0.95;
}
</style>
