<template>
  <content-panel>
    <div class="pt-5 d-flex">
      <div class="w-50">
        <router-link :to="{ name: 'Home' }"
          ><img src="../../assets/icons/home.svg" alt="" class="icon"
        /></router-link>
        /
        <router-link :to="{ name: 'Generaltypologies' }"
          >General Typologies</router-link
        >
        / {{ general_typology.name }} / Typologies
      </div>
      <form class="w-50">
        <input class="w-75" type="text" name="" id="" placeholder="Search" />
        <button>
          <img src="../../assets/icons/magnifier.svg" alt="" class="icon" />
        </button>
      </form>
    </div>
    <div class="pt-5">
      <section class="height-fit-content">
        <table class="table table-striped w-100">
          <thead>
            <tr>
              <th scope="col" class="w-50">Name</th>
              <th scope="col">Date</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in sortedItems" :key="item.id">
              <td>{{ item.name }}</td>
              <td>{{ item.createdAt }}</td>
              <td>
                <button
                  class="btn btn-secondary"
                  v-on:click="editTypology(item)"
                >
                  <img src="../../assets/icons/edit.svg" alt="" class="icon" />
                </button>
                <button
                  class="btn btn-danger"
                  v-on:click="removeTypology(item)"
                >
                  <img
                    src="../../assets/icons/garbage.svg"
                    alt=""
                    class="icon"
                  />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>

    <template #toolbar>
      <button class="btn btn-success" v-on:click="formAddTypology.show()">
        Create
      </button>
      <button
        class="btn btn-danger float-right"
        v-on:click="formRemoveGeneralTypology.show()"
      >
        Remove '{{ general_typology.name }}'
      </button>
      <button
        class="btn btn-secondary float-right"
        v-on:click="formEditGeneralTypology.show()"
      >
        Edit '{{ general_typology.name }}'
      </button>
    </template>
  </content-panel>
  <modal
    :title="`Edit ${general_typology.name}`"
    v-on:modal-close="formEditGeneralTypology.close()"
    :active="formEditGeneralTypology.visible"
    :width="50"
    :height="50"
  >
    <form
      class="p-5 text-center"
      v-on:submit.prevent="formEditGeneralTypology.submit"
    >
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="editGeneralTypology/name"
              class="w-100"
              type="text"
              placeholder="Name"
              :value="general_typology.name"
            />
          </td>
        </tr>
        <tr>
          <th>Date</th>
          <td>
            <input
              ref="editGeneralTypology/createdAt"
              class="w-100"
              type="text"
              placeholder="Date"
              :value="general_typology.createdAt"
              disabled
            />
          </td>
        </tr>
      </table>
      <input
        ref="editGeneralTypology/id"
        class="w-100"
        type="hidden"
        :value="general_typology.id"
      />
      <input
        ref="editGeneralTypology/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>
  <modal
    :title="`Remove ${general_typology.name}`"
    v-on:modal-close="formRemoveGeneralTypology.close()"
    :active="formRemoveGeneralTypology.visible"
    :width="50"
    :height="50"
  >
    <form
      class="p-5 text-center"
      v-on:submit.prevent="formRemoveGeneralTypology.submit"
    >
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="removeGeneralTypology/name"
              class="w-100"
              type="text"
              placeholder="Name"
              :value="general_typology.name"
              disabled
            />
          </td>
        </tr>
        <tr>
          <th>Date</th>
          <td>
            <input
              ref="removeGeneralTypology/createdAt"
              class="w-100"
              type="text"
              placeholder="Date"
              :value="general_typology.createdAt"
              disabled
            />
          </td>
        </tr>
        <tr style="color: red">
          <th>Impact</th>
          <td>
            <p>
              This action will delete {{ count }} typologies and their
              associations.
            </p>
            <p>
              Reminder: A typology can be linked with services and technologies
            </p>
          </td>
        </tr>
      </table>
      <input
        ref="removeGeneralTypology/id"
        class="w-100"
        type="hidden"
        :value="general_typology.id"
      />
      <input
        ref="removeGeneralTypology/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-danger mt-5" type="submit">Remove</button>
    </form>
  </modal>
  <modal
    title="Add Typology"
    v-on:modal-close="formAddTypology.close()"
    :active="formAddTypology.visible"
    :width="50"
    :height="50"
  >
    <form class="p-5 text-center" v-on:submit.prevent="formAddTypology.submit">
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="addTypology/name"
              class="w-100"
              type="text"
              placeholder="Name"
            />
          </td>
        </tr>
      </table>
      <input
        ref="addTypology/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>
  <modal
    :title="`Edit ${selectedItem.name}`"
    v-on:modal-close="formEditTypology.close()"
    :active="formEditTypology.visible"
    :width="50"
    :height="50"
  >
    <form class="p-5 text-center" v-on:submit.prevent="formEditTypology.submit">
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="editTypology/name"
              class="w-100"
              type="text"
              placeholder="Name"
              :value="selectedItem.name"
            />
          </td>
        </tr>
        <tr>
          <th>Date</th>
          <td>
            <input
              ref="editTypology/createdAt"
              class="w-100"
              type="text"
              placeholder="Date"
              :value="selectedItem.createdAt"
              disabled
            />
          </td>
        </tr>
      </table>
      <input
        ref="editTypology/id"
        class="w-100"
        type="hidden"
        :value="selectedItem.id"
      />
      <input
        ref="editTypology/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>

  <modal
    :title="`Remove ${selectedItem.name}`"
    v-on:modal-close="formRemoveTypology.close()"
    :active="formRemoveTypology.visible"
    :width="50"
    :height="50"
  >
    <form
      class="p-5 text-center"
      v-on:submit.prevent="formRemoveTypology.submit"
    >
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="removeTypology/name"
              class="w-100"
              type="text"
              placeholder="Name"
              :value="selectedItem.name"
              disabled
            />
          </td>
        </tr>
        <tr>
          <th>Date</th>
          <td>
            <input
              ref="removeTypology/createdAt"
              class="w-100"
              type="text"
              placeholder="Date"
              :value="selectedItem.createdAt"
              disabled
            />
          </td>
        </tr>
        <tr style="color: red">
          <th>Impact</th>
          <td>
            <p>
              This action will delete '{{ selectedItem.name }}' and his
              associations.
            </p>
            <p>
              Reminder: A typology can be linked with services and technologies
            </p>
          </td>
        </tr>
      </table>
      <input
        ref="removeTypology/id"
        class="w-100"
        type="hidden"
        :value="selectedItem.id"
      />
      <input
        ref="removeTypology/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>
</template>

<script>
import ContentPanel from "../../layout-components/ContentPanel";
import Modal from "../../components/Modal";
import { createForm } from "../../assets/js/FormManager";
export default {
  name: "Typologies",
  components: { ContentPanel, Modal },
  props: {
    general_typology_id: {
      required: true,
    },
  },
  data: function () {
    return {
      general_typology: {
        id: null,
        name: null,
      },
      items: [],
      count: 0,
      selectedItem: {
        id: null,
        name: null,
        createdAt: null,
      },
      formEditGeneralTypology: createForm(this, "editGeneralTypology"),
      formAddTypology: createForm(this, "addTypology"),
      formRemoveGeneralTypology: createForm(this, "removeGeneralTypology"),
      formEditTypology: createForm(this, "editTypology"),
      formRemoveTypology: createForm(this, "removeTypology"),
    };
  },
  created: async function () {
    this.$EventManager.triggerLoadingStart();
    this.formEditGeneralTypology.onSubmit(async (formData) => {
      await this.$API
        .get("general_typologies")
        .update(formData, this.$store.getters.token);
    });
    this.formEditGeneralTypology.afterSubmit(async () => {
      this.formEditGeneralTypology.close();
      await this.reload();
    });

    this.formAddTypology.onSubmit(async (formData) => {
      await this.$API
        .get("typologies")
        .create(this.general_typology_id, formData, this.$store.getters.token);
    });
    this.formAddTypology.afterSubmit(async () => {
      this.formAddTypology.close();
      await this.reload();
    });

    this.formRemoveGeneralTypology.onSubmit(async (formData) => {
      await this.$API
        .get("general_typologies")
        .remove(formData, this.$store.getters.token);
    });
    this.formRemoveGeneralTypology.afterSubmit(async () => {
      this.formRemoveGeneralTypology.close();
      this.$router.push({ name: "Generaltypologies" });
    });

    this.formEditTypology.onSubmit(async (formData) => {
      await this.$API
        .get("typologies")
        .update(this.general_typology_id, formData, this.$store.getters.token);
    });
    this.formEditTypology.afterSubmit(async () => {
      this.formEditTypology.close();
      await this.reload();
    });

    this.formRemoveTypology.onSubmit(async (formData) => {
      await this.$API
        .get("typologies")
        .remove(this.general_typology_id, formData, this.$store.getters.token);
    });
    this.formRemoveTypology.afterSubmit(async () => {
      this.formRemoveTypology.close();
      await this.reload();
    });
    await this.reload();
    this.$EventManager.triggerLoadingEnd();
  },
  computed: {
    sortedItems: function () {
      return this.items;
    },
  },
  methods: {
    reload: async function () {
      this.general_typology = await this.$API
        .get("general_typologies")
        .read(this.general_typology_id, this.$store.getters.token);

      let res = await this.$API
        .get("typologies")
        .list(this.general_typology_id, 20, 0, this.$store.getters.token);
      this.items = res.items;
      this.count = res.count;
    },
    editTypology: function (item) {
      this.selectedItem = item;
      this.formEditTypology.show();
    },
    removeTypology: function (item) {
      this.selectedItem = item;
      this.formRemoveTypology.show();
    },
  },
};
</script>

<style></style>
