import Request from "./request";
import Utils from "../Utils";

const SERVER = "main";

export async function execute(wanted, filters, limit, offset = 0, token) {
  try {
    return await Request.post(
      SERVER,
      `filter/paginate/${limit}/${offset}`,
      token,
      {
        wanted,
        filters,
      }
    );
  } catch (error) {
    Utils.debugVar("Connectors::filters::execute", error);
  }
}
