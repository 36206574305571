import Request from "./request";
import Utils from "../Utils";

const SERVER = "main";

export async function list(modality_id, technology_id, token) {
  try {
    return await Request.get(
      SERVER,
      `modality/${modality_id}/technology/${technology_id}/value_chain_columns`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::modalities::list", error);
  }
}

//  TODO: not implemented in the API
export async function filter(
  modality_id,
  technology_id,
  name,
  limit,
  offset = 0,
  token
) {
  try {
    return await Request.get(
      SERVER,
      `modality/${modality_id}/technology/${technology_id}/value_chain_columns/filter/${name}/${limit}/${offset}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::modalities::filter", error);
  }
}

export async function create(
  modality_id,
  technology_id,
  value_chain_column,
  token
) {
  try {
    return await Request.post(
      SERVER,
      `modality/${modality_id}/technology/${technology_id}/value_chain_columns`,
      token,
      {
        value_chain_column,
      }
    );
  } catch (error) {
    Utils.debugVar("Connectors::modalities::create", error);
  }
}

export async function read(modality_id, technology_id, id, token) {
  try {
    return await Request.get(
      SERVER,
      `modality/${modality_id}/technology/${technology_id}/value_chain_column/${id}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::modality::read", error);
  }
}

export async function update(
  modality_id,
  technology_id,
  value_chain_column,
  token
) {
  try {
    return await Request.put(
      SERVER,
      `modality/${modality_id}/technology/${technology_id}/value_chain_column/${value_chain_column.id}`,
      token,
      {
        value_chain_column,
      }
    );
  } catch (error) {
    Utils.debugVar("Connectors::modality::update", error);
  }
}

export async function remove(
  modality_id,
  technology_id,
  value_chain_column,
  token
) {
  try {
    return await Request.delete(
      SERVER,
      `modality/${modality_id}/technology/${technology_id}/value_chain_column/${value_chain_column.id}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::modality::remove", error);
  }
}
