<template>
  <content-panel>
    <div class="pt-5 d-flex">
      <div class="w-50">
        <router-link :to="{ name: 'Home' }"
          ><img src="../../assets/icons/home.svg" alt="" class="icon"
        /></router-link>
        / <router-link :to="{ name: 'Modalities' }"> Modalities </router-link>/
        {{ modality.name }} / Technologies
      </div>
      <form class="w-50">
        <input class="w-75" type="text" placeholder="Search" />

        <button>
          <img src="../../assets/icons/magnifier.svg" alt="" class="icon" />
        </button>
      </form>
    </div>
    <div class="pt-5">
      <section class="height-fit-content">
        <table class="table table-striped w-100">
          <thead>
            <tr>
              <th scope="col" class="w-50">Name</th>
              <th scope="col">Date</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in sortedItems" :key="item.name">
              <td>{{ item.name }}</td>
              <td>{{ item.createdAt }}</td>
              <td>
                <router-link
                  :to="{
                    name: 'TechnologyItem',
                    params: {
                      modality_id,
                      modality_json: JSON.stringify(modality),
                      technology_id: item.id,
                    },
                  }"
                  class="btn btn-secondary"
                  ><img
                    class="icon"
                    src="../../assets/icons/magnifier.svg"
                    alt=""
                /></router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>

    <template #toolbar>
      <button class="btn btn-success" v-on:click="formCreateTechnology.show()">
        Create
      </button>
      <button
        class="btn btn-danger float-right"
        v-on:click="formRemoveModality.show()"
      >
        Remove '{{ modality.name }}'
      </button>
      <button
        class="btn btn-secondary float-right"
        v-on:click="formEditModality.show()"
      >
        Edit '{{ modality.name }}'
      </button>
    </template>
  </content-panel>

  <modal
    :title="`Edit ${modality.name}`"
    v-on:modal-close="formEditModality.close()"
    :active="formEditModality.visible"
    :width="50"
    :height="50"
  >
    <form class="p-5 text-center" v-on:submit.prevent="formEditModality.submit">
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="editModality/name"
              class="w-100"
              type="text"
              placeholder="Name"
              :value="modality.name"
            />
          </td>
        </tr>
        <tr>
          <th>Date</th>
          <td>
            <input
              ref="editModality/createdAt"
              class="w-100"
              type="text"
              placeholder="Date"
              :value="modality.createdAt"
              disabled
            />
          </td>
        </tr>
      </table>
      <input
        ref="editModality/id"
        class="w-100"
        type="hidden"
        :value="modality.id"
      />
      <input
        ref="editModality/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>

  <modal
    title="Create technology"
    v-on:modal-close="formCreateTechnology.close()"
    :active="formCreateTechnology.visible"
    :width="50"
    :height="50"
  >
    <form
      class="p-5 text-center"
      v-on:submit.prevent="formCreateTechnology.submit"
    >
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="createTechnology/name"
              class="w-100"
              type="text"
              placeholder="Name"
            />
          </td>
        </tr>
      </table>
      <input
        ref="createTechnology/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>

  <modal
    :title="`Remove ${modality.name}`"
    v-on:modal-close="formRemoveModality.close()"
    :active="formRemoveModality.visible"
    :width="50"
    :height="50"
  >
    <form
      class="p-5 text-center"
      v-on:submit.prevent="formRemoveModality.submit"
    >
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="removeModality/name"
              class="w-100"
              type="text"
              placeholder="Name"
              :value="modality.name"
              disabled
            />
          </td>
        </tr>
        <tr>
          <th>Date</th>
          <td>
            <input
              ref="removeModality/createdAt"
              class="w-100"
              type="text"
              placeholder="Date"
              :value="modality.createdAt"
              disabled
            />
          </td>
        </tr>
        <tr style="color: red">
          <th>Impact</th>
          <td>
            <p>
              This action will delete the modality and {{ count }} technologies
              and their associations.
            </p>
            <p>Reminder: A technology contains value chain and services</p>
          </td>
        </tr>
      </table>
      <input
        ref="removeModality/id"
        class="w-100"
        type="hidden"
        :value="modality.id"
      />
      <input
        ref="removeModality/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-danger mt-5" type="submit">remove</button>
    </form>
  </modal>
</template>

<script>
import ContentPanel from "../../layout-components/ContentPanel";
import Modal from "../../components/Modal.vue";
import { createForm } from "../../assets/js/FormManager";

export default {
  name: "Technologie",
  components: { ContentPanel, Modal },
  props: {
    modality_id: {
      required: true,
    },
  },
  data: function () {
    return {
      items: [],
      count: 0,
      modality: {
        id: null,
        name: null,
        createdAt: null,
      },
      formCreateTechnology: createForm(this, "createTechnology"),
      formEditModality: createForm(this, "editModality"),
      formRemoveModality: createForm(this, "removeModality"),
    };
  },

  created: async function () {
    this.formCreateTechnology.onSubmit(async (formData) => {
      await this.$API
        .get("technologies")
        .create(this.modality.id, formData, this.$store.getters.token);
    });
    this.formCreateTechnology.afterSubmit(async () => {
      this.formCreateTechnology.close();
      await this.reload();
    });

    this.formEditModality.onSubmit(async (formData) => {
      await this.$API
        .get("modalities")
        .update(formData, this.$store.getters.token);
    });
    this.formEditModality.afterSubmit(async () => {
      this.formEditModality.close();
      await this.reload();
    });

    this.formRemoveModality.onSubmit(async (formData) => {
      await this.$API
        .get("modalities")
        .remove(formData, this.$store.getters.token);
    });
    this.formRemoveModality.afterSubmit(async () => {
      this.formRemoveModality.close();
      this.$router.push({ name: "Modalities" });
    });
    await this.reload();
  },
  computed: {
    sortedItems: function () {
      return this.items;
    },
  },
  methods: {
    reload: async function () {
      this.$EventManager.triggerLoadingStart();
      this.modality = await this.$API
        .get("modalities")
        .read(this.modality_id, this.$store.getters.token);
      let res = await this.$API
        .get("technologies")
        .list(this.modality.id, 20, 0, this.$store.getters.token);
      this.items = res.items;
      this.count = res.count;
      this.$EventManager.triggerLoadingEnd();
    },
  },
};
</script>

<style></style>
