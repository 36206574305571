export default class FormManager {
  constructor(vue, formName) {
    this.vue = vue;
    this.name = formName;
    this.inputs = [];
    this.data = {};
    this.data_given = {};
    this.beforeSubmitCallback = async () => {};
    this.afterSubmitCallBack = async () => {};
    this.onSubmitCallback = async () => {};
    this.beforeShowCallback = async () => {};
    this.afterShowCallback = async () => {};
    this.beforeHideCallback = async () => {};
    this.afterHideCallback = async () => {};
    this.visible = false;
    this.useLoading = true;
    this.storage = {};
    this.triggers = {};
  }

  beforeSubmit(callback) {
    this.beforeSubmitCallback = callback;
  }

  afterSubmit(callback) {
    this.afterSubmitCallBack = callback;
  }

  beforeShow(callback) {
    this.beforeShowCallback = callback;
  }

  afterShow(callback) {
    this.afterShowCallBack = callback;
  }

  beforeHide(callback) {
    this.beforeHideCallback = callback;
  }

  afterHide(callback) {
    this.afterHideCallBack = callback;
  }

  onSubmit(callback) {
    this.onSubmitCallback = callback;
  }

  resetInputs() {
    this.inputs = [];
    this.data = {};
    let keys = Object.keys(this.vue.$refs).filter((k) =>
      k.match(`^${this.name}/`)
    );
    for (let k of keys) {
      this.inputs.push(this.vue.$refs[k]);
      switch (this.vue.$refs[k].type) {
        case "checkbox":
          this.data[k.replace(RegExp(`^${this.name}/`), "")] =
            this.vue.$refs[k].checked;
          break;

        default:
          this.data[k.replace(RegExp(`^${this.name}/`), "")] =
            this.vue.$refs[k].value;
          break;
      }
    }
  }

  async submit() {
    if (this.useLoading) {
      this.vue.$EventManager.triggerLoadingStart();
    }

    this.resetInputs();

    await this.beforeSubmitCallback(this.inputs);

    await this.onSubmitCallback(this.data, this.data_given, this.inputs);

    await this.afterSubmitCallBack();

    if (this.useLoading) {
      this.vue.$EventManager.triggerLoadingEnd();
    }
  }

  async show(data = {}) {
    await this.beforeShowCallback();
    this.data_given = data;
    this.visible = true;
    await this.afterShowCallback();
  }

  async hide() {
    await this.beforeHideCallback();
    this.visible = false;
    await this.afterHideCallback();
  }

  async trigger(event, ...data) {
    if (Object.prototype.hasOwnProperty.call(this.triggers, event)) {
      await this.triggers[event](data);
    }
  }

  listen(event, callback) {
    this.triggers[event] = callback;
  }

  close() {
    this.hide();
    this.resetInputs();
    for (let input of this.inputs) {
      switch (input.type) {
        case "checkbox":
          input.checked = false;
          break;

        default:
          input.value = "";
          break;
      }
    }
    this.data_given = {};
    this.data = {};
  }
}

export function createForm(vue, formName) {
  return new FormManager(vue, formName);
}
