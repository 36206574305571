<template>
  <content-panel>
    <div class="pt-5 d-flex">
      <div class="w-50">
        <router-link :to="{ name: 'Home' }"
          ><img src="../../assets/icons/home.svg" alt="" class="icon"
        /></router-link>
        / <router-link :to="{ name: 'Companies' }"> Companies </router-link>/
        {{ company.name }}
      </div>
      <form class="w-50">
        <input class="w-75" type="text" name="" id="" placeholder="Search" />
        <button>
          <img src="../../assets/icons/magnifier.svg" alt="" class="icon" />
        </button>
      </form>
    </div>

    <div class="pt-5">
      <section class="height-fit-content">
        <table class="table w-100">
          <thead>
            <tr>
              <th scope="col">Property</th>
              <th scope="col">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Name</td>
              <td>
                <input class="w-100" type="text" :value="company.name" />
              </td>
            </tr>
            <tr>
              <td>Headquarter</td>
              <td>
                <input
                  class="w-100"
                  type="text"
                  :value="this.headquarter.name"
                />
              </td>
            </tr>
            <tr>
              <td>Headquarter continent</td>
              <td>
                <input
                  class="w-100"
                  type="text"
                  :value="this.headquarter.continent"
                />
              </td>
            </tr>
            <tr>
              <td>CreatedAt</td>
              <td>
                <input class="w-100" type="text" :value="company.createdAt" />
              </td>
            </tr>
            <tr v-if="companyServices.length > 0">
              <td>Services</td>
              <td>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col" class="w-25">Name</th>
                      <th scope="col">Parent</th>
                      <th scope="col">Typology</th>
                      <th scope="col">Column</th>
                      <th scope="col">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in companyServices" :key="item.id">
                      <td class="w-25">{{ item.name }}</td>
                      <td>{{ item.parent?.name }}</td>
                      <td>{{ item.typology?.name }}</td>
                      <td>{{ item.value_chain_column?.name }}</td>
                      <td>{{ item.createdAt }}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>

    <template #toolbar>
      <button
        class="btn btn-success"
        v-on:click="formAssociateServices.show(), getModalities()"
      >
        Associate services
      </button>
      <button
        class="btn btn-danger float-right"
        v-on:click="formRemoveCompany.show()"
      >
        Remove '{{ company.name }}'
      </button>
      <button
        class="btn btn-secondary float-right"
        v-on:click="formEditCompany.show()"
      >
        Edit '{{ company.name }}'
      </button>
    </template>
  </content-panel>

  <modal
    title="Associate services"
    v-on:modal-close="
      formAssociateServices.close(),
        (items = []),
        (selectedModality = {}),
        (techno = []),
        (selectedTechnology = {}),
        (value_chain_columns = []),
        (selectedServices = [])
    "
    :active="formAssociateServices.visible"
    :width="50"
    :height="50"
  >
    <form
      class="p-5 text-center"
      v-on:submit.prevent="formAssociateServices.submit"
    >
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Modalities</th>
          <td>
            <select
              ref="associateServices/modalities"
              class="form-control"
              v-model="selectedModality"
              v-on:change="technologiesByModalityId()"
              required
            >
              <option value="" selected disabled>Select a modality</option>
              <option v-for="item in items" :key="item.id" :value="item">
                {{ item.name }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <th>Technology</th>
          <td>
            <select
              v-if="selectedModality != ''"
              ref="associateServices/technologies"
              class="form-control"
              v-model="selectedTechnology"
              v-on:change="columnValueChainByModalityIdAndTechnologyId()"
              required
            >
              <option value="" selected disabled>Select a technologies</option>
              <option v-for="item in techno" :key="item.id" :value="item">
                {{ item.name }}
              </option>
            </select>
          </td>
          <td></td>
        </tr>
      </table>
      <input
        ref="createCompany/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <div v-if="selectedTechnology.name != null" class="mt-5">
        <th>Value Chain columns</th>
        <section class="height-fit-content p-4">
          <table class="table table-striped scrollable">
            <thead>
              <tr>
                <th scope="col">Selected</th>
                <th scope="col" class="w-25">Name</th>
                <th scope="col">Position</th>
                <th scope="col">DD</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in value_chain_columns" :key="item.id">
                <td>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      :checked="vcIsSelected(item)"
                      v-on:change="
                        onCheckVC(item), servicesByValueChainColumn()
                      "
                    />
                  </div>
                </td>
                <td class="w-25">{{ item.name }}</td>
                <td>{{ item.position }}</td>
                <td>{{ item.is_drug_discovery ? "Yes" : "No" }}</td>
                <td>{{ item.createdAt }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
      <div v-if="selectedValueChainColumn.length > 0" class="mt-5">
        <th class="mt-5">Services</th>
        <section class="height-fit-content">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Selected</th>
                <th scope="col" class="w-25">Name</th>
                <th scope="col">Parent</th>
                <th scope="col">Typology</th>
                <th scope="col">Column</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in services" :key="item.id">
                <td>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      :checked="serviceIsSelected(item)"
                      v-on:change="onCheckServices(item)"
                    />
                  </div>
                </td>
                <td class="w-25">{{ item.name }}</td>
                <td>{{ item.parent?.name }}</td>
                <td>{{ item.typology?.name }}</td>
                <td>{{ item.value_chain_column?.name }}</td>
                <td>{{ item.createdAt }}</td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
      <button
        v-if="selectedServices.length > 0"
        class="btn btn-primary mt-5"
        type="submit"
        v-on:click="addServicesAtCompagny()"
      >
        Submit
      </button>
    </form>
  </modal>

  <modal
    :title="`Edit ${company.name}`"
    v-on:modal-close="formEditCompany.close()"
    :active="formEditCompany.visible"
    :width="50"
    :height="50"
  >
    <form class="p-5 text-center" v-on:submit.prevent="formEditCompany.submit">
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="editCompany/name"
              class="w-100"
              type="text"
              placeholder="Name"
              :value="company.name"
            />
          </td>
        </tr>
        <tr>
          <th>Headquarter</th>
          <td>
            <select ref="editCompany/headquarter" class="form-control" required>
              <option value="" selected disabled>Select a headquarter</option>
              <option
                v-for="item in countries"
                :key="item.name"
                :value="JSON.stringify(item)"
                :selected="this.headquarter.name === item.name"
              >
                {{ item.name }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <th>Date</th>
          <td>
            <input
              ref="editCompany/createdAt"
              class="w-100"
              type="text"
              placeholder="Date"
              :value="company.createdAt"
              disabled
            />
          </td>
        </tr>
      </table>
      <input
        ref="editCompany/id"
        class="w-100"
        type="hidden"
        :value="company.id"
      />
      <input
        ref="editCompany/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>

  <modal
    :title="`Remove ${company.name}`"
    v-on:modal-close="formRemoveCompany.close()"
    :active="formRemoveCompany.visible"
    :width="50"
    :height="50"
  >
    <form
      class="p-5 text-center"
      v-on:submit.prevent="formRemoveCompany.submit"
    >
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="removeCompany/name"
              class="w-100"
              type="text"
              placeholder="Name"
              :value="company.name"
              disabled
            />
          </td>
        </tr>
        <tr>
          <th>Date</th>
          <td>
            <input
              ref="removeCompany/createdAt"
              class="w-100"
              type="text"
              placeholder="Date"
              :value="company.createdAt"
              disabled
            />
          </td>
        </tr>
        <tr style="color: red">
          <th>Impact</th>
          <td>
            <p>
              This action will delete '{{ company.name }}' and his associations.
            </p>
            <p>Reminder: ***</p>
          </td>
        </tr>
      </table>
      <input
        ref="removeCompany/id"
        class="w-100"
        type="hidden"
        :value="company.id"
      />
      <input
        ref="removeCompany/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>
</template>

<script>
import ContentPanel from "../../layout-components/ContentPanel";
import Modal from "../../components/Modal";
import { createForm } from "../../assets/js/FormManager";

export default {
  name: "CompanyItem",
  components: { ContentPanel, Modal },
  props: {
    company_id: {
      required: true,
    },
  },
  data: function () {
    return {
      countries: this.$API.get("searches").COUNTRIES.sort((a, b) => {
        return a.name.localeCompare(b.name);
      }),
      items: [],
      count: 0,
      techno: [],
      count_techno: 0,
      value_chain_columns: [],
      services: [],
      companyServices: [],
      selectedValueChainColumn: [],
      selectedServices: [],
      selectedModality: {
        id: null,
        name: null,
      },
      selectedTechnology: {
        id: null,
        name: null,
      },
      selectedVCColumn: {},
      company: {
        id: null,
        name: null,
        headquarter: "{}",
      },
      modalities: {
        id: null,
        name: null,
      },
      technologies: {
        id: null,
        name: null,
      },
      formAddCompany: createForm(this, "addCompany"),
      formRemoveCompany: createForm(this, "removeCompany"),
      formEditCompany: createForm(this, "editCompany"),
      formAssociateServices: createForm(this, "associateServices"),
      showCreateForm: false,
    };
  },
  created: async function () {
    await this.reload();

    this.formEditCompany.onSubmit(async (formData) => {
      await this.$API
        .get("companies")
        .update(formData, this.$store.getters.token);
    });
    this.formEditCompany.afterSubmit(async () => {
      this.formEditCompany.close();
      await this.reload();
    });

    this.formRemoveCompany.onSubmit(async (formData) => {
      await this.$API
        .get("companies")
        .remove(formData, this.$store.getters.token);
    });
    this.formRemoveCompany.afterSubmit(async () => {
      this.formRemoveCompany.close();
      this.$router.push({ name: "companies" });
    });
  },
  computed: {
    headquarter: function () {
      return JSON.parse(this.company.headquarter);
    },
  },
  methods: {
    reload: async function () {
      this.$EventManager.triggerLoadingStart();
      this.company = await this.$API
        .get("companies")
        .read(this.company_id, this.$store.getters.token);
      this.servicesByCompany();
      this.$EventManager.triggerLoadingEnd();
    },
    async getModalities() {
      let res = await this.$API
        .get("modalities")
        .list(50, 0, this.$store.getters.token);
      this.items = res.items;
      this.count = res.count;
    },
    async technologiesByModalityId() {
      let get_technologies = await this.$API
        .get("technologies")
        .list(this.selectedModality.id, 50, 0, this.$store.getters.token);
      this.techno = get_technologies.items;
      this.count_techno = get_technologies.count;
    },
    async columnValueChainByModalityIdAndTechnologyId() {
      let get_value_chain_columns = await this.$API
        .get("value_chain_columns")
        .list(
          this.selectedModality.id,
          this.selectedTechnology.id,
          this.$store.getters.token
        );
      this.value_chain_columns = get_value_chain_columns.value_chain_columns;
    },
    async servicesByValueChainColumn() {
      for (let i of this.selectedValueChainColumn) {
        let res = await this.$API
          .get("technologies")
          .get_services_by_vchainc(
            this.selectedModality.id,
            this.selectedTechnology.id,
            i.id,
            this.$store.getters.token
          );
        this.services = [...this.services, ...res.services];
      }
    },
    vcIsSelected: function (item) {
      return (
        this.selectedValueChainColumn.find((i) => i.id === item.id) !==
        undefined
      );
    },
    onCheckVC: function (item) {
      this.services = [];
      if (this.vcIsSelected(item)) {
        let index = this.selectedValueChainColumn.findIndex(
          (i) => i.id === item.id
        );
        this.selectedValueChainColumn.splice(index, 1);
      } else {
        this.selectedValueChainColumn.push(item);
      }
    },
    serviceIsSelected: function (item) {
      return this.selectedServices.find((i) => i.id === item.id) !== undefined;
    },
    onCheckServices: function (item) {
      if (this.serviceIsSelected(item)) {
        let index = this.selectedServices.findIndex((i) => i.id === item.id);
        this.selectedServices.splice(index, 1);
      } else {
        this.selectedServices.push(item);
      }
    },
    async addServicesAtCompagny() {
      for (let i of this.selectedServices) {
        await this.$API
          .get("companies")
          .add_service(this.company_id, i, this.$store.getters.token);
      }
      this.formAssociateServices.close();
      await this.reload();
    },
    async servicesByCompany() {
      let res = await this.$API
        .get("companies")
        .getServicesByCompagnyId(this.company_id, this.$store.getters.token);
      this.companyServices = res.services;
    },
  },
};
</script>

<style></style>
