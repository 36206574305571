import Request from "./request";
import Utils from "../Utils";

const SERVER = "main";

export async function list(token) {
  try {
    return await Request.get(SERVER, "logs", token);
  } catch (error) {
    Utils.debugVar("Connectors::logs::list", error);
  }
}

export async function download(file, token) {
  try {
    let log = await Request.get(SERVER, `log/${file}`, token);
    let fileURL = window.URL.createObjectURL(new Blob([log]));
    let fileLink = document.createElement("a");

    fileLink.href = fileURL;
    fileLink.setAttribute("download", file);

    fileLink.click();
  } catch (error) {
    Utils.debugVar("Connectors::log::download", error);
  }
}

export async function read(file, token) {
  try {
    return await Request.get(SERVER, `log/${file}`, token);
  } catch (error) {
    Utils.debugVar("Connectors::log::read", error);
  }
}
