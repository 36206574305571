import err401 from "../pages/errors/401";
import err403 from "../pages/errors/403";
import err404 from "../pages/errors/404";
import err500 from "../pages/errors/500";
import err501 from "../pages/errors/501";

export default [
  {
    name: "Err401",
    path: "/err/401",
    component: err401,
    props: true,
    meta: {
      authNotRequired: true,
      permissions: [],
    },
  },
  {
    name: "Err403",
    path: "/err/403",
    component: err403,
    props: true,
    meta: {
      authNotRequired: true,
      permissions: [],
    },
  },
  {
    name: "Err404",
    path: "/err/404",
    component: err404,
    props: true,
    meta: {
      authNotRequired: true,
      permissions: [],
    },
  },
  {
    name: "Err500",
    path: "/err/500",
    component: err500,
    props: true,
    meta: {
      authNotRequired: true,
      permissions: [],
    },
  },
  {
    name: "Err501",
    path: "/err/501",
    component: err501,
    props: true,
    meta: {
      authNotRequired: true,
      permissions: [],
    },
  },
];
