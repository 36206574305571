<template>
  <div>
    <button class="btn" v-on:click="previous" :disabled="!goPreviousIsEnabled">
      Previous
    </button>
    <span
      >{{ 1 + parseInt(index / offset) }} /
      {{ 1 + parseInt(indexMax / offset) }}</span
    >
    <button class="btn" v-on:click="next" :disabled="!goNextIsEnabled">
      Next
    </button>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      required: true,
      type: Number,
    },
    offset: {
      required: true,
      type: Number,
    },
    indexMax: {
      required: true,
      type: Number,
    },
  },
  computed: {
    goPreviousIsEnabled: function () {
      return this.index - this.offset >= 0;
    },
    goNextIsEnabled: function () {
      return this.index + this.offset <= this.indexMax;
    },
  },
  methods: {
    next: function () {
      if (this.goNextIsEnabled) this.$emit("next-page");
    },
    previous: function () {
      if (this.goPreviousIsEnabled) this.$emit("previous-page");
    },
  },
};
</script>

<style></style>
