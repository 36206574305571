<template>
  <content-panel>
    <div class="pt-5 d-flex">
      <div class="w-75">
        <img src="../../assets/icons/home.svg" alt="" class="icon" /> /
        <router-link :to="{ name: 'Modalities' }"> Modalities </router-link> /
        {{ modality.name }} /
        <router-link :to="{ name: 'Technologies' }"> Technologies </router-link
        >/
        {{ technology.name }}
      </div>
    </div>
    <div id="project" class="pt-5">
      <div class="row mx-0">
        <div class="col-md-6 mt-3">
          <h2>
            Typologies
            <button
              class="btn btn-success"
              v-on:click="formLinkTypology.show()"
            >
              <img
                class="icon icon-white"
                src="../../assets/icons/plus.svg"
                alt=""
              />
            </button>
          </h2>
          <section class="height-fit-content p-4">
            <table class="table table-striped scrollable">
              <thead>
                <tr>
                  <th scope="col">Typology</th>
                  <th scope="col">General Typology</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in sortedTypologies" :key="item.id">
                  <td>{{ item.name }}</td>
                  <td>{{ item.general_typology.name }}</td>
                  <td>
                    <button
                      class="btn btn-danger"
                      v-on:click="formUnlinkTypology.show(item)"
                    >
                      <img
                        class="icon"
                        src="../../assets/icons/broken-link.svg"
                        alt=""
                      />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>

        <div class="col-md-6 mt-3">
          <h2>
            Value Chain columns
            <button
              class="btn btn-success"
              v-on:click="formCreateVChainC.show()"
            >
              <img
                class="icon icon-white"
                src="../../assets/icons/plus.svg"
                alt=""
              />
            </button>
          </h2>
          <section class="height-fit-content p-4">
            <table class="table table-striped scrollable">
              <thead>
                <tr>
                  <th scope="col" class="w-25">Name</th>
                  <th scope="col">Position</th>
                  <th scope="col">DD</th>
                  <th scope="col">Date</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in sortedValueChainColumns" :key="item.id">
                  <td class="w-25">{{ item.name }}</td>
                  <td>{{ item.position }}</td>
                  <td>{{ item.is_drug_discovery ? "Yes" : "No" }}</td>
                  <td>{{ item.createdAt }}</td>
                  <td>
                    <button
                      class="btn btn-danger"
                      v-on:click="formRemoveVChainC.show(item)"
                    >
                      <img
                        class="icon"
                        src="../../assets/icons/garbage.svg"
                        alt=""
                      />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>
      </div>

      <h2 class="mt-5">
        Services
        <button class="btn btn-success" v-on:click="formCreateService.show()">
          <img
            class="icon icon-white"
            src="../../assets/icons/plus.svg"
            alt=""
          />
        </button>
      </h2>
      <section class="height-fit-content">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col" class="w-25">Name</th>
              <th scope="col">Parent</th>
              <th scope="col">Typology</th>
              <th scope="col">Column</th>
              <th scope="col">Date</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in sortedServices" :key="item.id">
              <td class="w-25">{{ item.name }}</td>
              <td>{{ item.parent?.name }}</td>
              <td>{{ item.typology?.name }}</td>
              <td>{{ item.value_chain_column?.name }}</td>
              <td>{{ item.createdAt }}</td>
              <td>
                <button
                  class="btn btn-secondary"
                  v-on:click="() => formEditService.show(item)"
                >
                  <img class="icon" src="../../assets/icons/edit.svg" alt="" />
                </button>
                <button
                  class="btn btn-success"
                  v-on:click="() => formCreateService.show(item)"
                >
                  <img class="icon" src="../../assets/icons/plus.svg" alt="" />
                </button>
                <button
                  class="btn btn-danger"
                  v-on:click="ShowDeleteServiceModal(item.name)"
                >
                  <img
                    class="icon"
                    src="../../assets/icons/garbage.svg"
                    alt=""
                  />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>

    <template #toolbar>
      <button class="btn btn-danger float-right">
        Remove '{{ technology.name }}'
      </button>
      <button class="btn btn-secondary float-right">
        Edit '{{ technology.name }}'
      </button>
    </template>
  </content-panel>

  <modal :title="'delete'" :active="false" :width="30" :height="30">
    <div class="px-5">
      <p>Are you sure ?</p>
      <p>You will remove the project named</p>
      <button class="btn btn-danger w-100 mt-3 py-3">
        <img class="icon" src="../../assets/icons/garbage.svg" alt="" />
        <span>Remove</span>
      </button>
    </div>
  </modal>

  <modal
    title="Link typology"
    v-on:modal-close="formLinkTypology.close()"
    :active="formLinkTypology.visible"
    :width="50"
    :height="50"
  >
    <form class="p-5 text-center" v-on:submit.prevent="formLinkTypology.submit">
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>
            General Typology
            <div
              v-if="formLinkTypology.storage.selected_general_typology_loading"
              class="spinner-border spinner-border-sm ml-2"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </th>
          <td class="w-50">
            <select
              ref="linkTypology/general_typology"
              class="form-control"
              required
              v-model="formLinkTypology.storage.selected_general_typology"
              v-on:change="
                formLinkTypology.trigger(
                  'selectGeneralTypo',
                  formLinkTypology.storage.selected_general_typology
                )
              "
            >
              <option value="" selected disabled>
                Select a general typology
              </option>
              <option
                v-for="item in formLinkTypology.storage.general_typologies"
                :key="item.id"
                :value="JSON.stringify(item)"
              >
                {{ item.name }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <th>
            Typology
            <div
              v-if="formLinkTypology.storage.selected_typology_loading"
              class="spinner-border spinner-border-sm ml-2"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </th>
          <td class="w-50">
            <select ref="linkTypology/typology" class="form-control" required>
              <option value="" selected disabled>Select a typology</option>
              <option
                v-for="item in formLinkTypology.storage.typologies"
                :key="item.id"
                :value="JSON.stringify(item)"
              >
                {{ item.name }}
              </option>
            </select>
          </td>
        </tr>
      </table>
      <input
        ref="linkTypology/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-primary mt-5" type="submit">Link</button>
    </form>
  </modal>
  <modal
    title="Unlink Typology"
    v-on:modal-close="formUnlinkTypology.close()"
    :active="formUnlinkTypology.visible"
    :width="50"
    :height="50"
  >
    <form
      class="p-5 text-center"
      v-on:submit.prevent="formUnlinkTypology.submit"
    >
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="unlinkTypology/name"
              class="w-100"
              type="text"
              placeholder="Name"
              :value="formUnlinkTypology.data_given.name"
              disabled
            />
          </td>
        </tr>
        <tr>
          <th>Date</th>
          <td>
            <input
              ref="unlinkTypology/createdAt"
              class="w-100"
              type="text"
              placeholder="Date"
              :value="formUnlinkTypology.data_given.createdAt"
              disabled
            />
          </td>
        </tr>
        <tr style="color: red">
          <th>Impact</th>
          <td>
            <p>
              This action will delete the link between the typology named '{{
                formUnlinkTypology.data_given.name
              }}' and the technology named '{{ technology.name }}'.
            </p>
            <p>
              The existant services which depend on this typology will no be
              deleted but will encour some issues
            </p>
          </td>
        </tr>
      </table>
      <input
        ref="unlinkTypology/id"
        class="w-100"
        type="hidden"
        :value="formUnlinkTypology.data_given.id"
      />
      <input
        ref="unlinkTypology/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-danger mt-5" type="submit">remove</button>
    </form>
  </modal>
  <modal
    title="Create a new column"
    v-on:modal-close="formCreateVChainC.close()"
    :active="formCreateVChainC.visible"
    :width="50"
    :height="50"
  >
    <form
      class="p-5 text-center"
      v-on:submit.prevent="formCreateVChainC.submit"
    >
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="createVChainC/name"
              class="w-100"
              type="text"
              placeholder="Name"
            />
          </td>
        </tr>
        <tr>
          <th>Position</th>
          <td>
            <input
              ref="createVChainC/position"
              class="w-100"
              type="number"
              placeholder="Position"
            />
          </td>
        </tr>
        <tr>
          <th>Is Drug Discovery ?</th>
          <td>
            <input
              ref="createVChainC/is_drug_discovery"
              class="w-100"
              type="checkbox"
              placeholder="is_drug_discovery"
            />
          </td>
        </tr>
      </table>
      <input
        ref="createVChainC/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>

  <modal
    title="Remove a column"
    v-on:modal-close="formRemoveVChainC.close()"
    :active="formRemoveVChainC.visible"
    :width="50"
    :height="50"
  >
    <form
      class="p-5 text-center"
      v-on:submit.prevent="formRemoveVChainC.submit"
    >
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="removeVChainC/name"
              class="w-100"
              type="text"
              placeholder="Name"
              :value="formRemoveVChainC.data_given.name"
              disabled
            />
          </td>
        </tr>
        <tr>
          <th>Position</th>
          <td>
            <input
              ref="removeVChainC/position"
              class="w-100"
              type="number"
              placeholder="Position"
              :value="formRemoveVChainC.data_given.position"
              disabled
            />
          </td>
        </tr>
        <tr>
          <th>Date</th>
          <td>
            <input
              ref="removeVChainC/createdAt"
              class="w-100"
              type="text"
              placeholder="createdAt"
              :value="formRemoveVChainC.data_given.createdAt"
              disabled
            />
          </td>
        </tr>
        <tr>
          <th>Is Drug Discovery ?</th>
          <td>
            <input
              ref="removeVChainC/is_drug_discovery"
              class="w-100"
              type="checkbox"
              placeholder="is_drug_discovery"
              :checked="formRemoveVChainC.data_given.is_drug_discovery"
              disabled
            />
          </td>
        </tr>
      </table>
      <input
        ref="removeVChainC/id"
        class="w-100"
        type="hidden"
        :value="formRemoveVChainC.data_given.id"
      />
      <input
        ref="removeVChainC/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-danger mt-5" type="submit">Remove</button>
    </form>
  </modal>

  <modal
    title="Create service"
    v-on:modal-close="formCreateService.close()"
    :active="formCreateService.visible"
    :width="50"
    :height="50"
  >
    <form
      class="p-5 text-center"
      v-on:submit.prevent="formCreateService.submit"
    >
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="createService/name"
              class="w-100"
              type="text"
              placeholder="Name"
            />
          </td>
        </tr>
        <tr v-if="formCreateService.data_given?.id">
          <th>Parent</th>
          <td>
            <select ref="createService/parent_id" class="form-control" disabled>
              <option :value="formCreateService.data_given.id" selected>
                {{ formCreateService.data_given.name }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <th>Typology</th>
          <td>
            <select
              ref="createService/typology_id"
              class="form-control"
              required
              :disabled="formCreateService.data_given?.id"
            >
              <option
                value=""
                :selected="!formCreateService.data_given?.id"
                disabled
              >
                Select a typology
              </option>
              <option
                v-for="item in this.typologies"
                :key="item.id"
                :value="item.id"
                :selected="
                  formCreateService.data_given?.typology?.id === item.id
                "
              >
                {{ item.general_typology.name }} / {{ item.name }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <th>Value chain column</th>
          <td>
            <select
              ref="createService/value_chain_column_id"
              class="form-control"
              required
              :disabled="formCreateService.data_given?.id"
            >
              <option
                value=""
                :selected="!formCreateService.data_given?.id"
                disabled
              >
                Select a column
              </option>
              <option
                v-for="item in this.value_chain_columns"
                :key="item.id"
                :value="item.id"
                :selected="
                  formCreateService.data_given?.value_chain_column?.id ===
                  item.id
                "
              >
                {{ item.name }}
              </option>
            </select>
          </td>
        </tr>
      </table>
      <input
        v-if="!formCreateService.data_given?.id"
        ref="createService/parent_id"
        class="w-100"
        type="hidden"
        :value="null"
      />
      <input
        ref="createService/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>
  <modal
    title="Edit service"
    v-on:modal-close="formEditService.close()"
    :active="formEditService.visible"
    :width="50"
    :height="50"
  >
    <form class="p-5 text-center" v-on:submit.prevent="formEditService.submit">
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="editService/name"
              class="w-100"
              type="text"
              placeholder="Name"
              :value="formEditService.data_given.name"
            />
          </td>
        </tr>
        <tr>
          <th>Parent</th>
          <td>
            <select
              ref="editService/parent_id"
              class="form-control"
              :disabled="formEditService.data_given.parent?.id"
            >
              <option
                value=""
                :selected="!formEditService.data_given.parent?.id"
                disabled
              >
                No parent
              </option>
              <option
                v-for="item in this.services"
                :key="item.id"
                :value="item.id"
                :selected="formEditService.data_given?.parent_id === item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <th>Typology</th>
          <td>
            <select
              ref="editService/typology_id"
              class="form-control"
              required
              :disabled="formEditService.data_given.parent?.id"
            >
              <option
                value=""
                :selected="!formEditService.data_given.parent?.id"
                disabled
              >
                Select a typology
              </option>
              <option
                v-for="item in this.typologies"
                :key="item.id"
                :value="item.id"
                :selected="formEditService.data_given?.typology?.id === item.id"
              >
                {{ item.general_typology.name }} / {{ item.name }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <th>Value chain column</th>
          <td>
            <select
              ref="editService/value_chain_column_id"
              class="form-control"
              required
              :disabled="formEditService.data_given.parent?.id"
            >
              <option
                v-for="item in this.value_chain_columns"
                :key="item.id"
                :value="item.id"
                :selected="
                  formEditService.data_given?.value_chain_column?.id === item.id
                "
              >
                {{ item.name }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <th>Date</th>
          <td>
            <input
              ref="editService/createdAt"
              class="w-100"
              type="text"
              placeholder="Name"
              :value="formEditService.data_given.createdAt"
              disabled
            />
          </td>
        </tr>
      </table>
      <input
        ref="editService/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <input
        ref="editService/id"
        class="w-100"
        type="hidden"
        :value="formEditService.data_given.id"
      />
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>
</template>

<script>
import ContentPanel from "../../layout-components/ContentPanel";
import Modal from "../../components/Modal.vue";
import { createForm } from "../../assets/js/FormManager";

export default {
  name: "TechnologieItem",
  components: { ContentPanel, Modal },
  props: {
    technology_id: {
      required: true,
    },
    modality_id: {
      required: true,
    },
    modality_json: {
      required: false,
      default: null,
    },
  },
  data: function () {
    return {
      technology: {
        id: null,
        name: null,
        createdAt: null,
      },
      modality: {
        id: null,
        name: null,
        createdAt: null,
      },
      typologies: [],
      value_chain_columns: [],
      services: [],
      formLinkTypology: createForm(this, "linkTypology"),
      formUnlinkTypology: createForm(this, "unlinkTypology"),
      formCreateVChainC: createForm(this, "createVChainC"),
      formRemoveVChainC: createForm(this, "removeVChainC"),
      formCreateService: createForm(this, "createService"),
      formEditService: createForm(this, "editService"),
    };
  },
  created: async function () {
    this.$EventManager.triggerLoadingStart();
    if (this.modality_json) {
      this.modality = JSON.parse(this.modality_json);
    } else {
      this.modality = await this.$API
        .get("modalities")
        .read(this.modality_id, this.$store.getters.token);
    }
    this.$EventManager.triggerLoadingEnd();

    this.formLinkTypology.storage = {
      selected_general_typology: "",
      selected_general_typology_loading: false,
      general_typologies: [],
      typologies: [],
    };

    this.formLinkTypology.beforeShow(async () => {
      this.formLinkTypology.storage.selected_general_typology_loading = true;
      let res = await this.$API
        .get("general_typologies")
        .list(50, 0, this.$store.getters.token);
      this.formLinkTypology.storage.general_typologies = res.items;
      this.formLinkTypology.storage.selected_general_typology_loading = false;
    });

    this.formLinkTypology.onSubmit(async (formData) => {
      let typo = JSON.parse(formData.typology);
      await this.$API
        .get("technologies")
        .add_typology(
          this.modality_id,
          this.technology_id,
          typo,
          this.$store.getters.token
        );
      this.formLinkTypology.close();
      await this.reload();
    });

    this.formLinkTypology.listen("selectGeneralTypo", async (data) => {
      this.formLinkTypology.storage.selected_typology_loading = true;
      let gtypo = JSON.parse(data[0]);
      let res = await this.$API
        .get("typologies")
        .list(gtypo.id, 50, 0, this.$store.getters.token);
      this.formLinkTypology.storage.typologies = res.items;
      this.formLinkTypology.storage.selected_typology_loading = false;
    });

    this.formUnlinkTypology.onSubmit(async (formData, item) => {
      await this.$API
        .get("technologies")
        .remove_typology(
          this.modality_id,
          this.technology_id,
          item,
          this.$store.getters.token
        );
      this.formUnlinkTypology.close();
      await this.reload();
    });

    this.formCreateVChainC.onSubmit(async (formData) => {
      await this.$API
        .get("value_chain_columns")
        .create(
          this.modality_id,
          this.technology_id,
          formData,
          this.$store.getters.token
        );
      this.formCreateVChainC.close();
      await this.reload();
    });

    this.formRemoveVChainC.onSubmit(async (formData) => {
      await this.$API
        .get("value_chain_columns")
        .remove(
          this.modality_id,
          this.technology_id,
          formData,
          this.$store.getters.token
        );
      this.formRemoveVChainC.close();
      await this.reload();
    });

    this.formCreateService.onSubmit(async (formData) => {
      await this.$API
        .get("services")
        .create(formData, this.$store.getters.token);
      this.formCreateService.close();
      await this.reload();
    });

    this.formEditService.onSubmit(async (formData) => {
      await this.$API
        .get("services")
        .update(formData, this.$store.getters.token);
      this.formEditService.close();
      await this.reload();
    });

    await this.reload();
  },
  computed: {
    sortedTypologies: function () {
      return this.typologies;
    },
    sortedServices: function () {
      return [...this.services].sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    },
    sortedValueChainColumns: function () {
      return [...this.value_chain_columns].sort((a, b) => {
        return a.position - b.position;
      });
    },
  },
  methods: {
    reload: async function () {
      this.$EventManager.triggerLoadingStart();
      this.technology = await this.$API
        .get("technologies")
        .read(this.modality_id, this.technology_id, this.$store.getters.token);

      let get_typologies = await this.$API
        .get("technologies")
        .get_typologies(
          this.modality_id,
          this.technology_id,
          this.$store.getters.token
        );
      this.typologies = get_typologies.typologies;

      let get_value_chain_columns = await this.$API
        .get("value_chain_columns")
        .list(this.modality_id, this.technology_id, this.$store.getters.token);
      this.value_chain_columns = get_value_chain_columns.value_chain_columns;

      let get_services = await this.$API
        .get("technologies")
        .get_services(
          this.modality_id,
          this.technology_id,
          this.$store.getters.token
        );
      this.services = get_services.services;

      this.$EventManager.triggerLoadingEnd();
    },
  },
};
</script>

<style scoped>
table.scrollable tbody {
  display: block;
  max-height: 350px;
  overflow-y: scroll;
  /*-ms-overflow-style: none;
  scrollbar-width: none;*/
}
table.scrollable thead,
table.scrollable tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
/*
table.scrollable tbody::-webkit-scrollbar {
  display: none;
}*/
</style>
