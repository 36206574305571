<template>
  <div id="forgotPassword" class="mx-auto">
    <section class="height-fit-content p-5" v-if="!$store.getters.isConnected">
      <form v-on:submit.prevent="submit">
        <div class="w-100 mb-3 text-center">
          <h2>Forgot Password</h2>
          <p class="text-left">
            Please enter your email address to receive a recovery link in your
            inbox
          </p>
        </div>
        <input
          class="w-100 p-3 m-2 border"
          v-model="form.email"
          type="text"
          name="email"
          placeholder="Email"
          required
        />
        <input
          class="w-100 p-3 m-2 btn btn-primary lrk-bg-dark-forced"
          type="submit"
          value="Submit"
        />
        <router-link :to="{ name: 'Login' }" class="w-100 m-2 btn btn-secondary"
          >I know my password</router-link
        >
      </form>
    </section>
    <section class="height-fit-content p-5" v-else>
      <div class="w-100 mb-3 text-center">
        <img src="../assets/icons/user.svg" width="100" alt="" />
      </div>
      <div class="text-center">
        <h2 class="text-center">{{ $store.getters.username }}</h2>
        <router-link :to="{ name: 'Home' }" class="btn btn-primary"
          >Go back to Home</router-link
        >
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      form: {
        email: null,
      },
    };
  },
  methods: {
    submit: async function () {
      this.$EventManager.triggerLoadingStart();
      await this.$API.get("users").forgotPassword(this.form.email);
      this.$EventManager.triggerLoadingEnd();
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style scoped>
#forgotPassword {
  margin-top: 10%;
  width: 500px;
}
</style>
