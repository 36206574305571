import Request from "./request";
import Utils from "../Utils";

const SERVER = "main";

export async function status() {
  try {
    return await Request.get(
      SERVER,
      `system/check_status`,
      null,
      {
        "CHECK_STATUS_SYS_NAME":"development",
        "CHECK_STATUS_SYS_PASS":"F3xSwGeLKH36F897Hemy6fkFk21BH29fMVoXzuJcE86kgxrkdu1eVAlAIiMigqvj",
      }
    );
  } catch (error) {
    Utils.debugVar("Connectors::check_api::status", error);
    return null;
  }
}
