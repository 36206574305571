<template>
  <div id="content-panel-display" class="col-md-12">
    <slot></slot>
  </div>

  <div id="content-panel-toolbar" class="col-md-12 p-4">
    <slot name="toolbar"></slot>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
