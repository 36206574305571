import Request from "./request";
import Utils from "../Utils";

const SERVER = "main";

export async function countCountry(token) {
  try {
    return await Request.get(SERVER, `stats/count_country`, token);
  } catch (error) {
    Utils.debugVar("Connectors::stats::countCountry", error);
  }
}

export async function countProject(token) {
  try {
    return await Request.get(SERVER, `stats/count_project`, token);
  } catch (error) {
    Utils.debugVar("Connectors::stats::countProject", error);
  }
}

export async function countBlacklist(token) {
  try {
    return await Request.get(SERVER, `stats/count_blacklist`, token);
  } catch (error) {
    Utils.debugVar("Connectors::stats::countBlacklist", error);
  }
}

export async function countBlacklistItem(token) {
  try {
    return await Request.get(SERVER, `stats/count_blacklist_item`, token);
  } catch (error) {
    Utils.debugVar("Connectors::stats::countBlacklistItem", error);
  }
}

export async function top5Countries(token) {
  try {
    return await Request.get(SERVER, `stats/top5countries`, token);
  } catch (error) {
    Utils.debugVar("Connectors::stats::top5Countries", error);
  }
}

export async function top5WordsUsed(token) {
  try {
    return await Request.get(SERVER, `stats/top5words_used`, token);
  } catch (error) {
    Utils.debugVar("Connectors::stats::top5WordsUsed", error);
  }
}
