<template>
  <div class="mt-5 mx-auto text-center">
    <img class="mb-3" src="../../assets/icons/shield.svg" width="200" alt="" />
    <h2 class="text-center">403</h2>
    <h3>Forbidden</h3>
    <router-link class="btn btn-primary" :to="{ name: 'Home' }"
      >Go back home</router-link
    >

    <section v-if="message" class="mt-5 height-fit-content" style="width: 350px">
      <div class="mb-4">
        <img
          class="float-left d-block mr-2"
          src="../../assets/icons/caution.svg"
          width="40"
          alt=""
        />
        <label class="d-block text-left pt-1" for="message"
          >Server message</label
        >
      </div>
      <textarea
        style="resize: none"
        class="w-100"
        name="message"
        id="message"
        :value="message"
        disabled
      ></textarea>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      default: null,
    },
  },
  mounted() {
    this.$EventManager.triggerLoadingEnd();
  },
};
</script>

<style></style>
