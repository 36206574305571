export default {
  connectors: {},
  install: function (app) {
    app.config.globalProperties.$API = {
      connectors: this.connectors,
      get: this.get,
    };
  },
  get: function (name) {
    return Object.prototype.hasOwnProperty.call(this.connectors, name)
      ? this.connectors[name]
      : null;
  },
  set: function (name, connector) {
    this.connectors[name] = connector;
  },
};
