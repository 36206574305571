import { createRouter, createWebHistory } from "vue-router";

/**
 * Other Router
 */
import Administration from "./administration";
import Metrics from "./metrics";
import Err from "./error";
import qualification from "./qualification";

/**
 * CLASSICS PAGES
 */
import Home from "../pages/main/Home";
import ProjectItem from "../pages/main/ProjectItem";
import ProjectItemGroup from "../pages/main/ProjectItemGroup";
import Projects from "../pages/main/Projects";
import BlacklistItem from "../pages/main/BlacklistItem";
import Blacklists from "../pages/main/Blacklists";
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";
import Profile from "../pages/main/Profile";

/**
 * EventManager
 */
import * as EventManager from "../assets/js/EventManager";

const routes = [
  {
    path: "/",
    authNotRequired: true,
    redirect: () => {
      return { name: "Login" };
    },
  },
  {
    name: "Home",
    path: "/home",
    component: Home,
    meta: {
      permissions: ["pages.main.home.read"],
    },
  },
  {
    name: "Login",
    path: "/login",
    component: Login,
    meta: {
      authNotRequired: true,
      disabledForAuthUser: true,
      permissions: [],
    },
  },
  {
    name: "ProjectItem",
    path: "/project/:id/:runId",
    component: ProjectItem,
    props: true,
    meta: {
      permissions: ["pages.main.projects.{id}.read"],
    },
  },
  {
    name: "ProjectItemGroup",
    path: "/project/:id",
    component: ProjectItemGroup,
    props: true,
    meta: {
      permissions: ["pages.main.projects.{id}.read"],
    },
  },
  {
    name: "Projects",
    path: "/projects",
    component: Projects,
    meta: {
      permissions: ["pages.main.projects.read"],
    },
  },
  {
    name: "BlacklistItem",
    path: "/blacklist/:id",
    component: BlacklistItem,
    props: true,
    meta: {
      permissions: ["pages.main.blacklists.{id}.read"],
    },
  },
  {
    name: "Blacklists",
    path: "/blacklists",
    component: Blacklists,
    meta: {
      permissions: ["pages.main.blacklists.read"],
    },
  },
  {
    name: "Profile",
    path: "/profile/:id",
    component: Profile,
    props: true,
    meta: {
      permissions: ["pages.main.profile.{id}.read"],
    },
  },
  {
    name: "ForgotPassword",
    path: "/forgot-password",
    component: ForgotPassword,
    meta: {
      authNotRequired: true,
      permissions: [],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    ...routes,
    ...Administration,
    ...Metrics,
    ...Err,
    ...qualification,
    {
      name: "404",
      path: "/*/",
      redirect: () => {
        return { name: "Err404" };
      },
    },
  ],
});

export function initFirewall(store) {
  router.beforeResolve((to) => {
    const requires = to.meta.permissions;

    if (to.matched.length <= 0) {
      return { name: "Err404" };
    }
    if (!store.getters.isConnected && !to.meta.authNotRequired) {
      return { name: "Login", query: { redirect: to.path } };
    } else if (store.getters.isConnected && to.name === "Login") {
      return { name: "Home" };
    }
    for (let req of requires) {
      let computedReq = computePermission(req, to.params);
      if (!Object.values(store.getters.permissions).includes(computedReq)) {
        return { name: "Err403" };
      }
    }
  });

  EventManager.onError((e) => {
    setTimeout(
      () =>
        router.push({
          name: "Err" + e.detail.code,
          params: { message: e.detail.message },
        }),
      10
    );
  });
}

function computePermission(permission, data) {
  let tab = permission.split(".");
  let final = [];
  for (const iterator of tab) {
    let match = iterator.match(/^\{(.+)\}$/);
    final.push(match ? data[match[1]] : iterator);
  }
  return final.join(".");
}

export default router;
