import Request from "./request";
import Utils from "../Utils";

const SERVER = "main";

export async function list(limit, offset = 0, token) {
  try {
    return await Request.get(
      SERVER,
      `projects/paginate/${limit}/${offset}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::projects::list", error);
  }
}

export async function read(id, token) {
  try {
    return await Request.get(SERVER, `project/${id}`, token);
  } catch (error) {
    Utils.debugVar("Connectors::projects::read", error);
  }
}

export async function create(name, user, token) {
  try {
    return await Request.post(SERVER, `projects`, token, { name, user });
  } catch (error) {
    Utils.debugVar("Connectors::projects::create", error);
  }
}

export async function update(id, name, token) {
  try {
    return await Request.put(SERVER, `project/${id}`, token, { name });
  } catch (error) {
    Utils.debugVar("Connectors::projects::update", error);
  }
}

export async function remove(id, user, token) {
  try {
    return await Request.delete(SERVER, `project/${id}`, token, { user });
  } catch (error) {
    Utils.debugVar("Connectors::projects::remove", error);
  }
}

export async function readRolePermission(project_id, role_id, token) {
  try {
    return await Request.get(
      SERVER,
      `project/${project_id}/permissions/role/${role_id}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::projects::readRolePermission", error);
  }
}

export async function addRolePermission(
  project_id,
  role_id,
  permission,
  token
) {
  try {
    return await Request.post(
      SERVER,
      `project/${project_id}/permissions`,
      token,
      {
        role: role_id,
        permission,
      }
    );
  } catch (error) {
    Utils.debugVar("Connectors::projects::addRolePermission", error);
  }
}

export async function removeRolePermission(
  project_id,
  role_id,
  permission,
  token
) {
  try {
    return await Request.delete(
      SERVER,
      `project/${project_id}/permissions`,
      token,
      {
        role: role_id,
        permission,
      }
    );
  } catch (error) {
    Utils.debugVar("Connectors::projects::addRolePermission", error);
  }
}

export function setProject(project) {
  localStorage.setItem("projectName", project.name);
  localStorage.setItem("projectId", project.id);
}

export function getProject() {
  return {
    name: localStorage.getItem("projectName"),
    id: localStorage.getItem("projectId"),
  };
}
