import Logs from "../pages/administration/Logs";
import Users from "../pages/administration/Users";
import Status from "../pages/administration/Status";
import Profile from "../pages/main/Profile";

export default [
  {
    name: "Logs",
    path: "/logs",
    component: Logs,
    meta: {
      permissions: ["pages.administration.logs.read"],
    },
  },
  {
    name: "Users",
    path: "/users",
    component: Users,
    meta: {
      permissions: ["pages.administration.users.read"],
    },
  },
  {
    name: "Status",
    path: "/status",
    component: Status,
    meta: {
      permissions: ["pages.administration.status.read"],
    },
  },
  {
    name: "UserItem",
    path: "/user/:id",
    component: Profile,
    props: true,
    meta: {
      permissions: ["pages.administration.users.read"],
    },
  },
];
