<template>
  <content-panel>
    <div class="pt-5 d-flex">
      <div class="w-75">
        <router-link :to="{ name: 'Home' }"
          ><img src="../../assets/icons/home.svg" alt="" class="icon"
        /></router-link>
        / Profile / {{ user.displayName }}
      </div>
      <form v-if="false" class="w-25">
        <input type="text" name="" id="" placeholder="Search" />
        <button>
          <img src="../../assets/icons/magnifier.svg" alt="" class="icon" />
        </button>
      </form>
    </div>

    <div class="pt-5">
      <section class="height-fit-content">
        <table class="table w-100">
          <thead>
            <tr>
              <th scope="col">Property</th>
              <th scope="col">Value</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Name</td>
              <td>
                <input class="w-100" type="text" v-model="user.displayName" />
              </td>
              <td>
                <button
                  :disabled="!canChangeUserInfo"
                  class="btn btn-success"
                  v-on:click.prevent="updateDisplayName"
                >
                  <img src="../../assets/icons/save.svg" alt="" class="icon" />
                </button>
              </td>
            </tr>
            <tr>
              <td>Password</td>
              <td>
                <input
                  class="w-100"
                  type="password"
                  v-model="user.password"
                  placeholder="*************"
                  minlength="5"
                />
              </td>
              <td>
                <button
                  :disabled="!canChangeUserInfo"
                  class="btn btn-success"
                  v-on:click.prevent="updatePassword"
                >
                  <img src="../../assets/icons/save.svg" alt="" class="icon" />
                </button>
              </td>
            </tr>
            <tr>
              <td>Email</td>
              <td>
                <input
                  class="w-100"
                  type="email"
                  :value="user.email"
                  disabled
                />
              </td>
              <td></td>
            </tr>
            <tr>
              <td>Roles</td>
              <td>
                <ul class="list-unstyled">
                  <li>
                    <input
                      class="m-2"
                      type="checkbox"
                      :disabled="!canChangeRole"
                      name="Trainee"
                      :checked="
                        user.roles.find((role) => role === 'Trainee') !==
                        undifined
                      "
                    />
                    <label for="Trainee">Trainee</label>
                  </li>
                  <li>
                    <input
                      class="m-2"
                      type="checkbox"
                      :disabled="!canChangeRole"
                      name="Team-Member"
                      :checked="
                        user.roles.find((role) => role === 'Team Member') !==
                        undifined
                      "
                    />
                    <label for="Team-Member">Team Member</label>
                  </li>
                  <li>
                    <input
                      class="m-2"
                      type="checkbox"
                      :disabled="!canChangeRole"
                      name="IT-Guys"
                      :checked="
                        user.roles.find((role) => role === 'IT Guys') !==
                        undifined
                      "
                    />
                    <label for="IT-Guys">IT Guys</label>
                  </li>
                  <li>
                    <input
                      class="m-2"
                      type="checkbox"
                      :disabled="!canChangeRole"
                      name="Admin"
                      :checked="
                        user.roles.find((role) => role === 'Admin') !==
                        undifined
                      "
                    />
                    <label for="Admin">Admin</label>
                  </li>
                </ul>
              </td>
              <td>
                <button v-if="canChangeRole" class="btn btn-success">
                  <img src="../../assets/icons/save.svg" alt="" class="icon" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>

    <template #toolbar>
      <button v-if="false" class="btn btn-success">Save all</button>
      <button
        v-if="canDeleteUser"
        v-on:click="showDelete = true"
        class="btn btn-danger float-right"
      >
        Remove '{{ user.displayName }}'
      </button>
    </template>

    <modal
      :title="'Remove user ' + user.displayName"
      :active="showDelete"
      :width="30"
      :height="30"
      v-on:modal-close="showDelete = false"
    >
      <div class="px-5">
        <p>Are you sure ?</p>
        <p>You will remove the user named '{{ user.displayName }}'</p>
        <p>(email: {{ user.email }})</p>
        <button
          class="btn btn-danger w-100 mt-3 py-3"
          v-on:click.prevent="remove"
        >
          <img class="icon" src="../../assets/icons/garbage.svg" alt="" />
          <span>Remove</span>
        </button>
      </div>
    </modal>
  </content-panel>
</template>

<script>
import ContentPanel from "../../layout-components/ContentPanel";
import Modal from "../../components/Modal";

export default {
  name: "Profile",
  components: { ContentPanel, Modal },
  props: {
    id: {
      required: true,
    },
  },
  data: function () {
    return {
      user: {
        displayName: null,
        password: null,
        roles: [],
      },
      showDelete: false,
    };
  },
  created: async function () {
    this.$EventManager.triggerLoadingStart();
    this.user = {
      ...this.user,
      ...(await this.$API
        .get("users")
        .read(this.id, this.$store.getters.token)),
    };
    this.$EventManager.triggerLoadingEnd();
  },
  computed: {
    canChangeUserInfo: function () {
      return (
        this.$store.getters.permissions.find(
          (perm) => perm === "pages.administration.users.update"
        ) ||
        this.$store.getters.permissions.find(
          (perm) => perm === `pages.main.profile.${this.id}.update`
        )
      );
    },
    canChangeRole: function () {
      return (
        this.$store.getters.permissions.find(
          (perm) => perm === "pages.administration.users.update"
        ) ||
        this.$store.getters.permissions.find(
          (perm) => perm === `pages.administration.users.${this.id}.update`
        )
      );
    },
    canDeleteUser: function () {
      return (
        this.$store.getters.permissions.find(
          (perm) => perm === "pages.administration.users.delete"
        ) ||
        this.$store.getters.permissions.find(
          (perm) => perm === `pages.administration.users.${this.id}.delete`
        )
      );
    },
  },
  methods: {
    updateDisplayName: async function () {
      this.$EventManager.triggerLoadingStart();
      this.user = {
        ...this.user,
        ...(await this.$API
          .get("users")
          .update(
            this.id,
            { displayName: this.user.displayName },
            this.$store.getters.token
          )),
      };
      this.$EventManager.triggerRefreshUserInfo();
      this.$EventManager.triggerLoadingEnd();
    },
    updatePassword: async function () {
      this.$EventManager.triggerLoadingStart();
      this.user = {
        ...this.user,
        ...(await this.$API
          .get("users")
          .update(
            this.id,
            { password: this.user.password },
            this.$store.getters.token
          )),
      };

      if (this.user.uid === this.$store.getters.uid) {
        this.$store.dispatch("login", {
          email: this.$store.state.user.data.email,
          password: this.user.password,
          callback: () => {
            this.$EventManager.triggerLogin();
            this.$EventManager.triggerLoadingEnd();
            this.user = { ...this.user, password: null };
          },
        });
      } else {
        this.user = { ...this.user, password: null };
        this.$EventManager.triggerLoadingEnd();
      }
    },
    remove: async function () {
      this.$EventManager.triggerLoadingStart();
      await this.$API.get("users").remove(this.id, this.$store.getters.token);
      this.$EventManager.triggerLoadingEnd();
      this.$router.push({ name: "Users" });
    },
  },
};
</script>

<style></style>
