<template>
  <content-panel>
    <div class="pt-5 d-flex">
      <div class="w-50">
        <router-link :to="{ name: 'Home' }"
          ><img src="../../assets/icons/home.svg" alt="" class="icon"
        /></router-link>
        / Blacklists
      </div>
      <form v-on:submit.prevent="filterByName" class="w-50">
        <input
          class="w-75"
          type="text"
          v-model="filter.name"
          placeholder="Search"
        />

        <button>
          <img src="../../assets/icons/magnifier.svg" alt="" class="icon" />
        </button>

        <button
          v-if="filter.name"
          class="btn-danger"
          v-on:click.prevent="cancelFilterByName"
        >
          <img src="../../assets/icons/cancel.svg" alt="" class="icon" />
        </button>
      </form>
    </div>
    <div class="pt-5">
      <section class="height-fit-content">
        <table class="table table-striped w-100">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.name">
              <td>{{ item.name }}</td>
              <td>
                <button
                  v-if="
                    blacklistEnabled.find((el) => el === item.id) === undefined
                  "
                  class="btn btn-success"
                  v-on:click="onClickEnable(item)"
                >
                  <img
                    src="../../assets/icons/switch.svg"
                    alt=""
                    class="icon"
                  />
                </button>
                <button
                  v-else
                  class="btn btn-danger"
                  v-on:click="onClickDisable(item)"
                >
                  <img
                    src="../../assets/icons/switch.svg"
                    alt=""
                    class="icon"
                  />
                </button>
                <router-link
                  :to="{ name: 'BlacklistItem', params: { id: item.id } }"
                  class="btn btn-secondary"
                  :disabled="!canReadBlacklist(item.id)"
                >
                  <img
                    src="../../assets/icons/magnifier.svg"
                    alt=""
                    class="icon"
                  />
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex w-100">
          <pagination
            class="w-75"
            :index="pagination.index"
            :index-max="pagination.indexMax"
            :offset="pagination.offset"
            @previous-page="previousPage"
            @next-page="nextPage"
          />
          <div class="w-25 text-right">{{ items.length }} item(s)</div>
        </div>
      </section>
    </div>

    <template #toolbar>
      <button
        class="btn btn-success"
        v-on:click="showCreateBlacklist = true"
        :disabled="!canCreateBlacklist()"
      >
        Create
      </button>
    </template>
  </content-panel>
  <modal
    title="Create blacklists"
    v-on:modal-close="(showCreateBlacklist = false), (createForm.name = '')"
    :active="showCreateBlacklist"
    :width="50"
    :height="50"
  >
    <form class="p-5 text-center" v-on:submit.prevent="create">
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              class="w-100"
              v-model="createForm.name"
              type="text"
              name="name"
              placeholder="Name"
            />
          </td>
        </tr>
      </table>
      <button
        class="btn btn-primary mt-5"
        type="submit"
        v-on:click="createBlacklist(), (createForm.name = '')"
      >
        Submit
      </button>
    </form>
  </modal>
</template>

<script>
import ContentPanel from "../../layout-components/ContentPanel";
import Modal from "../../components/Modal.vue";
import Pagination from "../../components/Pagination";

export default {
  name: "Blacklists",
  components: { ContentPanel, Modal, Pagination },
  data: function () {
    return {
      items: [],
      itemsEnabled: [],
      createForm: {
        name: null,
      },
      showCreateBlacklist: false,
      pagination: {
        index: 0,
        indexMax: 0,
        offset: 20,
      },
      filter: {
        name: null,
      },
    };
  },
  computed: {
    blacklistEnabled() {
      return this.itemsEnabled;
    },
  },

  methods: {
    async createBlacklist() {
      await this.$API
        .get("blacklists")
        .create(
          this.createForm.name,
          this.$store.getters.uid,
          this.$store.getters.token
        );
      this.$EventManager.triggerRefreshUserInfo(() => this.refresh());
      this.showCreateBlacklist = false;
    },
    onClickEnable(blacklist) {
      this.$API.get("blacklists").enable(blacklist);
      this.itemsEnabled = this.$API.get("blacklists").getEnabled();
    },
    onClickDisable(blacklist) {
      this.$API.get("blacklists").disable(blacklist);
      this.itemsEnabled = this.$API.get("blacklists").getEnabled();
    },
    refresh: async function () {
      if (this.filter.name && this.filter.name.trim() !== "") {
        await this.filterByName();
      } else {
        let res = await this.$API
          .get("blacklists")
          .list(
            this.pagination.offset,
            this.pagination.index,
            this.$store.getters.token
          );
        this.items = res.items;
        this.pagination.indexMax = res.count;
      }
    },
    previousPage: async function () {
      this.$EventManager.triggerLoadingStart();
      this.pagination.index -= this.pagination.offset;
      await this.refresh();
      document.body.scrollTop = 0;
      this.$EventManager.triggerLoadingEnd();
    },
    nextPage: async function () {
      this.$EventManager.triggerLoadingStart();
      this.pagination.index += this.pagination.offset;
      await this.refresh();
      document.body.scrollTop = 0;
      this.$EventManager.triggerLoadingEnd();
    },
    filterByName: async function () {
      if (this.filter.name && this.filter.name.trim() !== "") {
        let res = await this.$API
          .get("blacklists")
          .filter(
            this.filter.name,
            this.pagination.offset,
            this.pagination.index,
            this.$store.getters.token
          );
        this.items = res.items;
        this.pagination.indexMax = res.count;
      } else {
        await this.refresh();
      }
    },
    cancelFilterByName: async function () {
      this.filter.name = null;
      await this.refresh();
    },
    canCreateBlacklist: function () {
      return this.$store.getters.permissions.find(
        (perm) => perm === "pages.main.blacklists.create"
      );
    },
    canReadBlacklist: function (id) {
      return (
        this.$store.getters.permissions.find(
          (perm) => perm === "pages.main.blacklists.read"
        ) ||
        this.$store.getters.permissions.find(
          (perm) => perm === `pages.main.blacklists.${id}.read`
        )
      );
    },
  },
  created: async function () {
    this.$EventManager.triggerLoadingStart();
    await this.refresh();
    this.itemsEnabled = this.$API.get("blacklists").getEnabled();
    this.$EventManager.triggerLoadingEnd();
  },
};
</script>

<style></style>
