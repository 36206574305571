import Request from "./request";
import Utils from "../Utils";

const SERVER = "main";

export async function latest(token) {
  try {
    return await Request.get(SERVER, `searches/latest`, token);
  } catch (error) {
    Utils.debugVar("Connectors::searches::lastest", error);
  }
}

export async function getByRunId(runId, limit = 50, offset = 0, token) {
  try {
    return await Request.get(
      SERVER,
      `searches/${runId}/paginate/${limit}/${offset}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::searches::getByRunId", error);
  }
}

export async function list(project, user, limit = 50, offset = 0, token) {
  try {
    const works = await Request.get(
      SERVER,
      `searches/paginate/${project}/${user}/${limit}/${offset}`,
      token
    );
    return {
      items: parseWork(works.items),
      count: works.count,
    };
  } catch (error) {
    Utils.debugVar("Connectors::searches::list", error);
  }
}

export async function getSearchByGroup(project, limit = 50, offset = 0, token) {
  try {
    return await Request.get(
      SERVER,
      `searches/groups/paginate/${project}/${limit}/${offset}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::searches::getSearchByGroupRunId", error);
  }
}

/*
@deprecated Cette fonction n'est plus utilisé, les recherches sont partagés à tout les membres qui on accès au projet
 */
export async function filter(
  project,
  user,
  query,
  limit = 50,
  offset = 0,
  token
) {
  try {
    const works = await Request.get(
      SERVER,
      `searches/filter/${project}/${user}/${query}/${limit}/${offset}`,
      token
    );
    return {
      items: parseWork(works.items),
      count: works.count,
    };
  } catch (error) {
    Utils.debugVar("Connectors::searches::filter", error);
  }
}

export async function filterRunid(
  runId,
  country,
  limit = 50,
  offset = 0,
  token
) {
  try {
    return await Request.get(
      SERVER,
      `searches/filter/${runId}/${country}/${limit}/${offset}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::searches::filterRunid", error);
  }
}
export async function filterQueryAndCountry(
  project,
  query,
  limit = 50,
  offset = 0,
  token
) {
  try {
    return await Request.get(
      SERVER,
      `searches/filter/query/${project}/${query}/${limit}/${offset}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::searches::filterQueryAndCountry", error);
  }
}

export async function create(
  query,
  nbResult,
  country,
  tags,
  project,
  blacklist,
  user,
  runId,
  token
) {
  try {
    let language = "EN";
    let location = country;
    return await Request.post(SERVER, "searches", token, {
      query,
      nbResult,
      country,
      language,
      location,
      tags: tags.split(",").map(i => i.trim()),
      project,
      blacklist,
      user,
      runId,
    });
  } catch (error) {
    Utils.debugVar("Connectors::searches::create", error);
  }
}

export async function remove(id, user, token) {
  try {
    return await Request.delete(SERVER, `search/${id}`, token, { user });
  } catch (error) {
    Utils.debugVar("Connectors::searches::remove", error);
  }
}

function parseWork(data) {
  //  Change "None" => null
  Object.entries(data).forEach((work) => {
    Object.entries(work[1]).forEach((workAttribute) => {
      if (workAttribute[1] === "None") {
        data[work[0]][workAttribute[0]] = null;
      }
    });
  });
  return data.map((element) => {
    let status = "done";
    return {
      ...element,
      status,
    };
  });
}

export function download(project, item) {
  try {
    let fileURL = window.URL.createObjectURL(new Blob([item.result_raw]));
    let fileLink = document.createElement("a");

    fileLink.href = fileURL;
    fileLink.setAttribute(
      "download",
      `[${item.created_at}]-${project.name.replaceAll(
        " ",
        "-"
      )}-${item.value.replaceAll(" ", "-")}-${item.country.replaceAll(
        " ",
        "-"
      )}.csv`
    );

    fileLink.click();
  } catch (error) {
    Utils.debugVar("Connectors::searches::download", error);
  }
}

export function downloadSelection(project, items) {
  try {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;

    let results = items.map((item) => item.result_raw);
    let resultConcatened = results.join("\n");
    let fileURL = window.URL.createObjectURL(new Blob([resultConcatened]));
    let fileLink = document.createElement("a");

    fileLink.href = fileURL;
    fileLink.setAttribute(
      "download",
      `lenseo-${project.name.replaceAll(" ", "-")}-(${today}).csv`
    );

    fileLink.click();
  } catch (error) {
    Utils.debugVar("Connectors::searches::downloadSelection", error);
  }
}

export function selectionToArray(items) {
  try {
    let results = items.map((item) => item.result);
    let resultConcatened = results.join("\n");
    return {
      fakeItem: { result: resultConcatened },
      data: toArray({ result: resultConcatened }),
    };
  } catch (error) {
    Utils.debugVar("Connectors::searches::downloadSelection", error);
  }
}

export function toArray(item) {
  try {
    let lines = item.result_raw.split("\n");
    let res = [];
    for (const line of lines) {
      res.push(line.split("\t"));
    }
    return res;
  } catch (error) {
    Utils.debugVar("Connectors::searches::toArray", error);
    return [];
  }
}

export const COUNTRIES = [
  {
    name: "Albania",
    code: "al",
    continent: "Europe",
  },
  {
    name: "Argentina",
    code: "ar",
    continent: "South America",
  },
  {
    name: "Australia",
    code: "au",
    continent: "Oceania",
  },
  {
    name: "Austria",
    code: "at",
    continent: "Europe",
  },
  {
    name: "Belgium",
    code: "be",
    continent: "Europe",
  },
  {
    name: "Brazil",
    code: "br",
    continent: "South America",
  },
  {
    name: "Bulgaria",
    code: "bg",
    continent: "Europe",
  },
  {
    name: "Canada",
    code: "ca",
    continent: "North America",
  },
  {
    name: "Chile",
    code: "cl",
    continent: "South America",
  },
  {
    name: "China",
    code: "cn",
    continent: "Asia",
  },
  {
    name: "Costa Rica",
    code: "cr",
    continent: "North America",
  },
  {
    name: "Croatia",
    code: "hr",
    continent: "Europe",
  },
  {
    name: "Cyprus",
    code: "cy",
    continent: "Europe",
  },
  {
    name: "Czech Republic",
    code: "cz",
    continent: "Europe",
  },
  {
    name: "Denmark",
    code: "dk",
    continent: "Europe",
  },
  {
    name: "Estonia",
    code: "ee",
    continent: "Europe",
  },
  {
    name: "Finland",
    code: "fi",
    continent: "Europe",
  },
  {
    name: "France",
    code: "fr",
    continent: "Europe",
  },
  {
    name: "Germany",
    code: "de",
    continent: "Europe",
  },
  {
    name: "Greece",
    code: "gr",
    continent: "Europe",
  },
  {
    name: "Honduras",
    code: "hn",
    continent: "North America",
  },
  {
    name: "Hong Kong",
    code: "hk",
    continent: "Asia",
  },
  {
    name: "Hungary",
    code: "hu",
    continent: "Europe",
  },
  {
    name: "Iceland",
    code: "is",
    continent: "Europe",
  },
  {
    name: "India",
    code: "in",
    continent: "Asia",
  },
  {
    name: "Indonesia",
    code: "id",
    continent: "Asia",
  },
  {
    name: "Ireland",
    code: "ie",
    continent: "Europe",
  },
  {
    name: "Israel",
    code: "il",
    continent: "Asia",
  },
  {
    name: "Italy",
    code: "it",
    continent: "Europe",
  },
  {
    name: "Japan",
    code: "jp",
    continent: "Asia",
  },
  {
    name: "Korea",
    code: "kr",
    continent: "Asia",
  },
  {
    name: "Latvia",
    code: "lv",
    continent: "Europe",
  },
  {
    name: "Lithuania",
    code: "lt",
    continent: "Europe",
  },
  {
    name: "Luxembourg",
    code: "lu",
    continent: "Europe",
  },
  {
    name: "Malaysia",
    code: "my",
    continent: "Asia",
  },
  {
    name: "Mexico",
    code: "mx",
    continent: "North America",
  },
  {
    name: "Moldova",
    code: "md",
    continent: "Europe",
  },
  {
    name: "Morocco",
    code: "ma",
    continent: "Africa",
  },
  {
    name: "Netherlands",
    code: "nl",
    continent: "Europe",
  },
  {
    name: "New Zealand",
    code: "nz",
    continent: "Oceania",
  },
  {
    name: "Norway",
    code: "no",
    continent: "Europe",
  },
  {
    name: "Poland",
    code: "pl",
    continent: "Europe",
  },
  {
    name: "Portugal",
    code: "pt",
    continent: "Europe",
  },
  {
    name: "Romania",
    code: "ro",
    continent: "Europe",
  },
  {
    name: "Russian Federation",
    code: "ru",
    continent: "Asia",
  },
  {
    name: "Singapore",
    code: "sg",
    continent: "Asia",
  },
  {
    name: "Slovakia",
    code: "sk",
    continent: "Europe",
  },
  {
    name: "Slovenia",
    code: "si",
    continent: "Europe",
  },
  {
    name: "South Africa",
    code: "za",
    continent: "Africa",
  },
  {
    name: "Spain",
    code: "es",
    continent: "Europe",
  },
  {
    name: "Sweden",
    code: "se",
    continent: "Europe",
  },
  {
    name: "Switzerland",
    code: "ch",
    continent: "Europe",
  },
  {
    name: "Thailand",
    code: "th",
    continent: "Asia",
  },
  {
    name: "Tunisia",
    code: "tn",
    continent: "Africa",
  },
  {
    name: "Turkey",
    code: "tr",
    continent: "Asia",
  },
  {
    name: "Ukraine",
    code: "ua",
    continent: "Europe",
  },
  {
    name: "United Arab Emirates",
    code: "ae",
    continent: "Asia",
  },
  {
    name: "United Kingdom",
    code: "uk",
    continent: "Europe",
  },
  {
    name: "United States",
    code: "us",
    continent: "North America",
  },
  {
    name: "Uruguay",
    code: "uy",
    continent: "South America",
  },
  {
    name: "Venezuela",
    code: "ve",
    continent: "South America",
  },
  {
    name: "Viet Nam",
    code: "vn",
    continent: "Asia",
  },
];
