<template>
  <content-panel>
    <div class="pt-5 d-flex">
      <div class="w-50">
        <router-link :to="{ name: 'Home' }"
          ><img src="../../assets/icons/home.svg" alt="" class="icon"
        /></router-link>
        / Filters
      </div>
      <form class="w-50">
        <input class="w-75" type="text" name="" id="" placeholder="Search" />
        <button>
          <img src="../../assets/icons/magnifier.svg" alt="" class="icon" />
        </button>
      </form>
    </div>
    <div class="pt-5">
      <section class="height-fit-content">
        <div class="row mx-0">
          <filter-item
            v-for="item in filters"
            :key="item.uid"
            @remove-item="(uid) => removeFilter(uid)"
            @update="(update) => updateFilter(item.uid, update)"
            :name="item.uid"
            :has-next="item.uid !== filters[filters.length - 1].uid"
            :is-wanted="item.uid === filters[0].uid"
          />
          <filter-item :create-btn="true" @create-item="createFilter" />
        </div>
      </section>
    </div>

    <div v-if="items.length > 0" class="pt-5">
      <section class="height-fit-content">
        <table class="table table-striped w-100">
          <tr>
            <th v-for="(field, index) in itemFields" :key="index">
              {{ field }}
            </th>
          </tr>
          <tr v-for="item in items" :key="item.id">
            <td
              v-for="(elm, index) in Object.values(item)"
              :key="index + '.' + item.id"
            >
              {{ elm }}
            </td>
          </tr>
        </table>
      </section>
    </div>

    <template #toolbar>
      <button
        class="btn btn-success"
        :disabled="filters.length < 1"
        v-on:click="execute"
      >
        Compute
      </button>
      <button class="btn btn-danger float-right" v-on:click="filters = []">
        Clear all
      </button>
    </template>
  </content-panel>
</template>

<script>
import ContentPanel from "../../layout-components/ContentPanel";
import FilterItem from "../../components/FilterItem";
export default {
  name: "Filters",
  components: { ContentPanel, FilterItem },
  data: function () {
    return {
      filters: [],
      items: [],
      count: 0,
    };
  },
  computed: {
    itemFields: function () {
      return this.items.length > 0 ? Object.keys(this.items[0]) : [];
    },
  },
  methods: {
    createFilter: function () {
      this.filters.push({ uid: this.$uid() });
    },
    removeFilter: function (uid) {
      let index = this.filters.findIndex((elm) => elm.uid === uid);
      this.filters.splice(index, 1);
    },
    updateFilter: function (uid, update) {
      let index = this.filters.findIndex((elm) => elm.uid === uid);
      this.filters[index] = update;
    },

    execute: async function () {
      this.items = [];
      this.count = 0;
      let filters_copy = [...this.filters];
      for (let index = 0; index < filters_copy.length; index++) {
        filters_copy[index].entity = filters_copy[index].entity
          .toLowerCase()
          .replaceAll(" ", "_");
        let f = {};
        for (const key of Object.keys(filters_copy[index].fields)) {
          f[key.toLowerCase().replaceAll(" ", "_")] =
            filters_copy[index].fields[key];
        }
        filters_copy[index].fields = f;
      }

      let wanted = filters_copy[0];
      if (Object.keys(wanted.fields).length == 0) {
        filters_copy.shift();
      }
      let res = await this.$API
        .get("filters")
        .execute(wanted, filters_copy, 20, 0, this.$store.getters.token);
      this.items = res.items;
      this.count = res.count;
    },
  },
};
</script>

<style></style>
