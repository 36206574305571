<template>
  <button
    v-if="createBtn"
    class="btn btn-success filter-item"
    v-on:click="createItem"
  >
    <img class="icon" src="../assets/icons/plus.svg" alt="" />
  </button>
  <div class="btn btn-secondary filter-item" v-else>
    <div>
      <div class="float-left p-3">Filter {{ name }}</div>
      <button class="btn btn-danger float-right" v-on:click="removeItem">
        <img src="../assets/icons/cancel.svg" alt="" class="icon" />
      </button>
    </div>
    <div>
      <select
        class="py-2 w-100 mt-1 border text-center bg-white"
        v-model="entity"
        v-on:change="sendUpdate"
        required
      >
        <option value="" disabled selected>Entity</option>
        <option
          v-for="(fields, entity) in entities"
          :key="entity"
          :value="entity"
        >
          {{ entity }}
        </option>
      </select>
      <select
        class="py-2 w-100 mt-1 border text-center bg-white"
        v-model="field"
        v-on:change="sendUpdate"
        required
      >
        <option value="" disabled selected>Field</option>
        <option
          v-for="item in selectedEntity"
          :key="entity + '.' + item"
          :value="item"
        >
          {{ item }}
        </option>
      </select>
      <div class="d-flex mt-1">
        <input
          id="no_value"
          class="mr-1"
          type="checkbox"
          placeholder="no_value"
          v-model="haveValue"
        />
        <input
          type="text"
          class="py-1 w-100 border text-center bg-white"
          placeholder="Value"
          v-model="value"
          v-on:keyup="sendUpdate"
          :disabled="!haveValue"
        />
      </div>
    </div>
  </div>
  <button
    v-if="hasNext && !(isWanted && field.trim() == '')"
    v-on:click="changeOperand"
    class="btn"
  >
    {{ operatorAnd ? "AND" : "OR" }}
  </button>
  <img
    v-if="hasNext && isWanted && field.trim() == ''"
    src="../assets/icons/next.svg"
    alt=""
    class="icon"
  />
</template>

<script>
export default {
  name: "FilterItem",
  props: {
    createBtn: Boolean,
    name: String,
    hasNext: Boolean,
    isWanted: Boolean,
    content: Object,
  },
  data: function () {
    return {
      haveValue: true,
      operatorAnd: false,
      entity: "",
      field: "",
      value: null,
      entities: {
        Company: ["name", "headquarter"],
        Modality: ["name"],
        Technology: ["name"],
        "Value Chain Column": ["name", "is drug discovery"],
        "General Typology": ["name"],
        Typology: ["name"],
        Service: ["name"],
        Site: ["name"],
      },
    };
  },
  computed: {
    selectedEntity: function () {
      return this.entity ? this.entities[this.entity] : [];
    },
  },
  methods: {
    createItem: function () {
      this.$emit("create-item");
    },
    removeItem: function () {
      this.$emit("remove-item", this.name);
    },
    changeOperand: function () {
      this.operatorAnd = !this.operatorAnd;
      this.sendUpdate();
    },
    sendUpdate: function () {
      let data = {};

      if (this.isWanted && this.field.trim() == "") {
        data = {
          uid: this.name,
          entity: this.entity,
          operator: null,
          fields: {},
        };
      } else {
        data = {
          uid: this.name,
          entity: this.entity,
          operator: this.operatorAnd ? "AND" : "OR",
          fields: {},
        };
      }

      if (this.field && this.field.trim() != "") {
        data.fields[this.field] = !isNaN(this.value)
          ? Number.isInteger(this.value)
            ? parseInt(this.value)
            : parseFloat(this.value)
          : `%${this.value}%`;
      }

      this.$emit("update", data);
    },
  },
};
</script>

<style scoped>
.filter-item {
  width: 300px;
  height: 200px;
  margin: 10px;
  padding: 5px;
}

.filter-item input[type="checkbox"] {
  width: 30px;
  height: 35px;
  border-radius: 0%;
}
</style>
