import Request from "./request";
import Utils from "../Utils";

const SERVER = "main";

export async function list(pageToken, token) {
  try {
    const users = await Request.get(
      SERVER,
      `users/${pageToken ? pageToken : ""}`,
      token
    );
    return {
      items: users,
    };
  } catch (error) {
    Utils.debugVar("Connectors::users::list", error);
  }
}

export async function read(userId, token) {
  try {
    const user = await Request.get(SERVER, `user/${userId}`, token);
    return user;
  } catch (error) {
    Utils.debugVar("Connectors::users::read", error);
  }
}

export async function update(userId, data, token) {
  try {
    const user = await Request.patch(SERVER, `user/${userId}`, token, {
      ...data,
    });
    return user;
  } catch (error) {
    Utils.debugVar("Connectors::users::update", error);
  }
}

export async function create(data, token) {
  try {
    const user = await Request.post(SERVER, "users", token, {
      ...data,
    });
    return user;
  } catch (error) {
    Utils.debugVar("Connectors::users::create", error);
  }
}

export async function remove(id, token) {
  try {
    await Request.delete(SERVER, `user/${id}`, token);
  } catch (error) {
    Utils.debugVar("Connectors::users::create", error);
  }
}

export async function forgotPassword(email) {
  try {
    const user = await Request.post(SERVER, "users/resetPassword", null, {
      email,
    });
    return user;
  } catch (error) {
    Utils.debugVar("Connectors::users::forgotPassword", error);
  }
}

export async function roleList(token) {
  try {
    return await Request.get(SERVER, "users/roles", token);
  } catch (error) {
    Utils.debugVar("Connectors::users::forgotPassword", error);
  }
}

export async function readRessourcePermission(
  ressource,
  ressource_id,
  uid,
  token
) {
  try {
    return await Request.get(
      SERVER,
      `user/${uid}/${ressource}/${ressource_id}/permissions`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::projects::readRolePermission", error);
  }
}
