<template>
  <content-panel>
    <h2 class="mt-5">Services</h2>
    <div class="row mx-0 w-100 height-fit-content">
      <div class="col text-center">
        <section class="height-fit-content p-2 bg-success">
          <section class="height-fit-content p-4">
            <img src="../../assets/icons/server.svg" width="50" alt="" />
            <h3>Status</h3>
          </section>
        </section>
      </div>
      <div class="col text-center">
        <section class="height-fit-content p-2 bg-success">
          <section class="height-fit-content p-4">
            <img src="../../assets/icons/server.svg" width="50" alt="" />
            <h3>Waiter</h3>
          </section>
        </section>
      </div>
      <div class="col text-center">
        <section class="height-fit-content p-2 bg-danger">
          <section class="height-fit-content p-4">
            <img src="../../assets/icons/server.svg" width="50" alt="" />
            <h3>Metrics</h3>
          </section>
        </section>
      </div>
      <div class="col text-center">
        <section class="height-fit-content p-2 bg-success">
          <section class="height-fit-content p-4">
            <img src="../../assets/icons/database.svg" width="50" alt="" />
            <h3>Database</h3>
          </section>
        </section>
      </div>
    </div>
    <h2 class="mt-5">Latest events</h2>
    <section class="height-fit-content">
      <textarea class="w-100" rows="10" disabled></textarea>
    </section>
  </content-panel>
</template>

<script>
import ContentPanel from "../../layout-components/ContentPanel";

export default {
  components: { ContentPanel },
};
</script>

<style></style>
